import { Component, OnInit,Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {

  
  closeBtnName: string;
  title:string;
  color:string;
  list: any[] = [];
  //@Input('title') title: string = "";
  //@Input('color') color: string = "";

  constructor(public bsModalRef: BsModalRef) {}
 
  ngOnInit() {
    this.list.push('PROFIT!!!');
  }
  verifyColor(){
    if(this.color=='error'){
      return true;
    }
    else{
      return false;
    }
  }
}
