export class Product {
    constructor(
        
                public id: number,
                public name: string,
                public images: Array<any>,
                public oldPrice: number,
                public newPrice: number,
                public cost: number,
                public discount: number,
                public ratingsCount: number,
                public ratingsValue: number,
                public description: string,
                public availibilityCount: number,
                public availibilityReal:number,
                public cartCount: number,
                public catalogCount: number=1,
                public color: Array<string>,
                public size: Array<any>,
                public codsiz:string ,
                public weight: number,
                public categoryId: number,
                public flagOffer: string,
                public origPrice: string,
                
                ){ }
  }


