<body (scroll)="onScroll($event)">
  <header class="header" style="height: 120px; position: absolute">
    <!--start navbar-->
    <nav
      class="navbar navbar-expand-lg fixed-top bg-transparent"
      [ngClass]="{ affix: scrollvalue }"
    >
      <div class="container">
        <a class="navbar-brand" href="index.html">
          <img
            src="assets/img/fcm_push_icon.png"
            alt="logo"
            class="dynamic-img"
            width="100"
            height="100"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="ti-menu"></span>
        </button>

        <div
          class="collapse navbar-collapse h-auto"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ml-auto menu">
            <li>
              <a
                href="#about"
                class="page-scroll"
                style="font-weight: bold; color: white"
                >ConRuda</a
              >
            </li>
            <li>
              <a
                href="#process"
                class="page-scroll"
                style="font-weight: bold; color: white"
                >Como funciona</a
              >
            </li>
            <li>
              <a
                href="#screenshots"
                class="page-scroll"
                style="font-weight: bold; color: white"
                >Pantallas</a
              >
            </li>
            <li>
              <a
                href="#testimonial"
                class="page-scroll"
                style="font-weight: bold; color: white"
                >Referencias
              </a>
            </li>
            <li>
              <a
                href="#pricing"
                class="page-scroll"
                style="font-weight: bold; color: white"
                >Precios</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <!--header section end-->

  <div class="main">

    <a
      href="https://api.whatsapp.com/send?phone=+51956711403&text=Hola InfinitoDhd, quisiera mas información de conruda."
      class="float-wb"
      target="_blank"
    >
      <i class="fa fa-whatsapp my-float"></i>
    </a>
    <!--hero section start-->
    <section class="ptb-100" image-overlay="10" style="padding-top: 115px; ">
      <div class="container">
        <div
          class="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center"
        >
          <div class="col-md-12 col-lg-6">
            <div class="section-heading text-white py-5">
              <h1 class="text-white font-weight-bold">
                Utiliza ConRuda en tu negocio
              </h1>
              <p>
                Administra tu negocio como todo un Pro.
              </p>
              <div class="action-btns mt-4">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a
                      href="https://play.google.com/store/apps/details?id=infinitodhd.conruda.services"
                      target="_blank"
                      class="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                      style="background-color: white"
                    >
                      <span
                        class="fab fa-google-play icon-size-sm mr-3 color-primary"
                      ></span>
                      <div class="download-text text-left">
                        <small>Descarganos en</small>
                        <h5 class="mb-0">Google Play</h5>
                      </div>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="#"
                      class="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                      style="background-color: white"
                    >
                      <span
                        class="fab fa-apple icon-size-sm mr-3 color-primary"
                      ></span>
                      <div class="download-text text-left">
                        <small>Pronto en </small>
                        <h5 class="mb-0">App Store</h5>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-sm-9 col-lg-6">
            <div class="hero-animation-img">
              <img
                class="img-fluid position-relative img-custom-width"
                src="assets/img/hero-new-img.svg"
                alt="animation image"
              />
            </div>
          </div>
        </div>
        <!--end of row-->
      </div>
      <!--end of container-->
    </section>
    <!--hero section end-->
    <!--faq or accordion section start-->
    <section id="faq" class="ptb-100 gray-light-bg" style="margin-top: 50px;" >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9 col-lg-8">
            <div class="section-heading text-center mb-5">
              <h2>¿Es dificil administrar un negocio?</h2>
              <p></p>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-12 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
            <div class="img-wrap">
              <img
                src="assets/img/850x676.png"
                alt="download"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div id="accordion" class="accordion faq-wrap">
              <div class="card mb-3">
                <a
                  class="card-header"
                  data-toggle="collapse"
                  href="#collapse0"
                  aria-expanded="false"
                >
                  <h6 class="mb-0 d-inline-block">
                    90% de los emprendedores no tienen éxito
                  </h6>
                </a>
                <div
                  id="collapse0"
                  class="collapse show"
                  data-parent="#accordion"
                  style=""
                >
                  <div class="card-body white-bg">
                    <p>
                      ¿Poner un negocio y no morir en el intento? Es lo que
                      muchos queremos, en nuestro camino por lograr nuestros
                      sueños y dejar de trabajar por el sueño de otro; sin
                      embargo más del 90% no tienen éxito según la Asociación de
                      Emprendedores de Perú. El 90% pasamos por está pena.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card my-3">
                <a
                  class="card-header collapsed"
                  data-toggle="collapse"
                  href="#collapse1"
                  aria-expanded="false"
                >
                  <h6 class="mb-0 d-inline-block">¿Buscaste como administrar correctamente?</h6>
                </a>
                <div
                  id="collapse1"
                  class="collapse"
                  data-parent="#accordion"
                  style=""
                >
                  <div class="card-body white-bg">
                    <p>
                      ¿No te ha pasado que hay demasiada información muchas
                      veces sin orden y nos pasamos buscando una gran cantidad
                      de tiempo? ¿o finalmente decidimos por algún curso, pero
                      debemos esperar algunos meses o tratar de acoplar nuestra
                      rutina a esta modalidad?.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card my-3">
                <a
                  class="card-header collapsed"
                  data-toggle="collapse"
                  href="#collapse2"
                  aria-expanded="false"
                >
                  <h6 class="mb-0 d-inline-block">
                    ¿Lanzaste tu negocio y no ganas lo suficiente?
                  </h6>
                </a>
                <div
                  id="collapse2"
                  class="collapse"
                  data-parent="#accordion"
                  style=""
                >
                  <div class="card-body white-bg">
                    <p>
                      Los más arriesgados nos lanzamos y como no sabemos administrar, perdemos dinero, mezclamos el dinero de nuestro hogar con el del negocio y muchos otros errores.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card mt-3">
                <a
                  class="card-header collapsed"
                  data-toggle="collapse"
                  href="#collapse3"
                  aria-expanded="false"
                >
                  <h6 class="mb-0 d-inline-block">
                    ¿No sabes que hay en tu negocio?
                  </h6>
                </a>
                <div
                  id="collapse3"
                  class="collapse"
                  data-parent="#accordion"
                  style=""
                >
                  <div class="card-body white-bg">
                    <p>
                      Es muy común que los negocios que recién inician pierdan el control sobre el invetario, y solo lleven un control de caja, es por eso que necesitas un sistema. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--faq or accordion section end-->

    <!--promo section start-->
    <section class="promo-section ptb-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8">
            <div class="section-heading text-center">
              <h2>¿Cómo te ayudamos?</h2>
              <p>diseñamos nuestro servicio pensando en</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-md-center justify-content-sm-center">
          <div class="col-md-6 col-lg-4">
            <div
              class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4"
            >
              <div class="card-body">
                <div class="pb-2">
                  <span class="fas fa-clock icon-size-lg color-primary"></span>
                </div>
                <div class="pt-2 pb-3">
                  <h5>Mejorar Ventas </h5>
                  <p class="mb-0">
                    Contarás con un catálogo digital, el cual podrás compartir en tus redes sociales y con tus clientes habituales.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div
              class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4"
            >
              <div class="card-body">
                <div class="pb-2">
                  <span
                    class="fas fa-money-bill icon-size-lg color-primary"
                  ></span>
                </div>
                <div class="pt-2 pb-3">
                  <h5>Controla tu inventario</h5>
                  <p class="mb-0">
                    Con nuestro sistema tendrás el control completo y constante de tu inventario, no te preocupes si no eres ordenado, ConRuda, casi te obliga a hacer invetarios periódicos. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div
              class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4"
            >
              <div class="card-body">
                <div class="pb-2">
                  <span
                    class="fas fa-user-lock icon-size-lg color-primary"
                  ></span>
                </div>
                <div class="pt-2 pb-3">
                  <h5>Bajo costo  </h5>
                  <p class="mb-0">
                    Contamos con planes personalizados para cada tipo de negocio y el nivel de tus rentas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--promo section end-->

    <!--about us section start-->
    <!--about us section start-->
    <div class="overflow-hidden">
      <!--about us section start-->
      <section
        id="about"
        class="position-relative overflow-hidden feature-section ptb-100 gray-light-bg"
      >
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-12 col-lg-6">
              <div class="feature-contents section-heading">
                <h2>Te presentamos a ConRuda</h2>
                <p>
                  ConRuda es una aplicación que te ayuda a administrar tu negocio, 
                  día a día
                </p>

                <ul class="check-list-wrap list-two-col py-3">
                  <li>Catálogo digital</li>
                  <li>Ventas en linea y en tu local</li>
                  <li>Control de inventario</li>
                  <li>Reportes completos</li>
                  <li>Controla tus entregas</li>
                  <li>Administra tus gastos</li>
                </ul>

                <div class="action-btns mt-4">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a
                        href="https://play.google.com/store/apps/details?id=infinitodhd.conruda.services"
                        target="_blank"
                        class="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                        style="
                          background-color: white;
                          border: solid 1px #00a859;
                        "
                      >
                        <span
                          class="fab fa-google-play icon-size-sm mr-3 color-primary"
                        ></span>
                        <div class="download-text text-left">
                          <small>Descarganos en</small>
                          <h5 class="mb-0">Google Play</h5>
                        </div>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="#"
                        class="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                        style="
                          background-color: white;
                          border: solid 1px #00a859;
                        "
                      >
                        <span
                          class="fab fa-apple icon-size-sm mr-3 color-primary"
                        ></span>
                        <div class="download-text text-left">
                          <small>Pronto en </small>
                          <h5 class="mb-0">App Store</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mask-image">
                <img
                  src="assets/img/about1.png"
                  class="img-fluid"
                  alt="about"
                />
                <div class="item-icon video-promo-content">
                  <a
                    href="https://youtu.be/YmBaC4EViDY"
                    class="popup-youtube video-play-icon text-center m-auto"
                    ><span class="ti-control-play"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--about us section end-->
    </div>
    <!--about us section end-->
    <!--about us section end-->

    <section
      id="process"
      class="work-process-section position-relative ptb-100"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9 col-lg-8">
            <div class="section-heading text-center mb-5">
              <h2>¿Cómo funciona?</h2>
              <p>
                Cuando crees tu primer producto el asesor de configuración te solicitará información para configurar todas las funciones de ConRuda. 
              </p>
            </div>
          </div>
        </div>
        <div
          class="row align-items-center justify-content-md-center justify-content-sm-center"
        >
          <div class="col-md-12 col-lg-6">
            <div class="work-process-wrap">
              <div class="process-single-item">
                <div class="process-icon-item left-shape">
                  <div class="d-flex align-items-center">
                    <div class="process-icon mr-4">
                      <i class="fas fa-lightbulb color-primary"></i>
                    </div>
                    <div class="process-content text-left">
                      <h5>Regístrate</h5>
                      <p>
                        Solo necesitas un correo para hacerlo. 
                      </p>
                    </div>
                  </div>
                  <svg x="0px" y="0px" width="312px" height="130px">
                    <path
                      class="dashed1"
                      fill="none"
                      stroke="rgb(95, 93, 93)"
                      stroke-width="1"
                      stroke-dasharray="1300"
                      stroke-dashoffset="0"
                      d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                    ></path>
                    <path
                      class="dashed2"
                      fill="none"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-dasharray="6"
                      stroke-dashoffset="1300"
                      d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="process-single-item">
                <div class="process-icon-item right-shape">
                  <div class="d-flex align-items-center">
                    <div class="process-icon ml-4">
                      <i class="fas fa-list-alt color-primary"></i>
                    </div>
                    <div class="process-content text-right">
                      <h5>Configura tu negocio </h5>
                      <p>
                        Nuestra app te ayudará a configurar tu negocio, tomando datos para utilizar las funciones de ConRuda.
                      </p>
                    </div>
                  </div>
                  <svg x="0px" y="0px" width="312px" height="130px">
                    <path
                      class="dashed1"
                      fill="none"
                      stroke="rgb(95, 93, 93)"
                      stroke-width="1"
                      stroke-dasharray="1300"
                      stroke-dashoffset="0"
                      d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                    ></path>
                    <path
                      class="dashed2"
                      fill="none"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-dasharray="6"
                      stroke-dashoffset="1300"
                      d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="process-single-item">
                <div class="process-icon-item left-shape mb-0">
                  <div class="d-flex align-items-center">
                    <div class="process-icon mr-4">
                      <i class="fas fa-cash-register color-primary"></i>
                    </div>
                    <div class="process-content text-left">
                      <h5>Operación de negocio</h5>
                      <p>
                        Tendras tu punto de venta, catalogo on line, tu página
                        web de ventas lista para usar y otras opciones.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="img-wrap">
              <img
                src="assets/img/850x900.png"
                alt="features"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="screenshots" class="screenshots-section pb-100 gray-light-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9 col-lg-8">
            <div class="section-heading text-center mb-5">
              <h2>Con Ruda</h2>
              <p>Ve lo simple que hacemos todo.</p>
            </div>
          </div>
        </div>

        <div class="screenshot-wrap">
          <div class="screenshot-frame"></div>
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide
              ><img
                src="assets/img/phone/1.png"
                class="img-fluid"
                alt="screenshots"
            /></ng-template>
            <ng-template carouselSlide
              ><img
                src="assets/img/phone/2.png"
                class="img-fluid"
                alt="screenshots"
            /></ng-template>
            <ng-template carouselSlide
              ><img
                src="assets/img/phone/3.png"
                class="img-fluid"
                alt="screenshots"
            /></ng-template>
            <ng-template carouselSlide
              ><img
                src="assets/img/phone/4.png"
                class="img-fluid"
                alt="screenshots"
            /></ng-template>
            <ng-template carouselSlide
              ><img
                src="assets/img/phone/5.png"
                class="img-fluid"
                alt="screenshots"
            /></ng-template>
            <ng-template carouselSlide
              ><img
                src="assets/img/phone/6.png"
                class="img-fluid"
                alt="screenshots"
            /></ng-template>
            <ng-template carouselSlide
              ><img
                src="assets/img/phone/7.png"
                class="img-fluid"
                alt="screenshots"
            /></ng-template>
            <ng-template carouselSlide
              ><img
                src="assets/img/phone/8.png"
                class="img-fluid"
                alt="screenshots"
            /></ng-template>
          </owl-carousel-o>
        </div>
        <!--end app screen carousel-->
      </div>
    </section>
    <section class="position-relative gradient-bg ptb-100" id="testimonial">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-5 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
            <div class="testimonial-heading text-white">
              <h2 class="text-white">
                Qué dicen los clientes acerca de nosotros
              </h2>
              <p>Mira los comentarios de algunos de nuestros clientes.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="testimonial-content-wrap">
              <owl-carousel-o
                [options]="testimonialOptions"
                class="owl-theme client-testimonial-1 dot-indicator testimonial-shape"
              >
                <ng-template carouselSlide>
                  <div class="item">
                    <div class="testimonial-quote-wrap">
                      <div class="media author-info mb-3">
                        <div class="author-img mr-3">
                          <img
                            src="assets/img/500x500b.png"
                            alt="client"
                            class="img-fluid"
                          />
                        </div>
                        <div class="media-body text-white">
                          <h5 class="mb-0 text-white">Vane Abarca</h5>
                          <span>Emprendedora</span>
                        </div>
                        <i class="fas fa-quote-right text-white"></i>
                      </div>
                      <div class="client-say text-white">
                        <p>Esta mas amigable, que su primera versión</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="item">
                    <div class="testimonial-quote-wrap">
                      <div class="media author-info mb-3">
                        <div class="author-img mr-3">
                          <img
                            src="assets/img/500x500b.png"
                            alt="client"
                            class="img-fluid"
                          />
                        </div>
                        <div class="media-body text-white">
                          <h5 class="mb-0 text-white">Mariela Roque</h5>
                          <span>Emprendedora</span>
                        </div>
                        <i class="fas fa-quote-right text-white"></i>
                      </div>
                      <div class="client-say text-white">
                        <p>
                          Me la imagen para el producto me gustaria q sea
                          cuadrada... para q se acomode mejor el producto.
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="item">
                    <div class="testimonial-quote-wrap">
                      <div class="media author-info mb-3">
                        <div class="author-img mr-3">
                          <img
                            src="assets/img/500x500a.png"
                            alt="client"
                            class="img-fluid"
                          />
                        </div>
                        <div class="media-body text-white">
                          <h5 class="mb-0 text-white">Marco B.</h5>
                          <span>Emprendedor</span>
                        </div>
                        <i class="fas fa-quote-right text-white"></i>
                      </div>
                      <div class="client-say text-white">
                        <p>
                          Me gusta la herramienta de ventas, es facil de usar.
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="item">
                    <div class="testimonial-quote-wrap">
                      <div class="media author-info mb-3">
                        <div class="author-img mr-3">
                          <img
                            src="assets/img/500x500a.png"
                            alt="client"
                            class="img-fluid"
                          />
                        </div>
                        <div class="media-body text-white">
                          <h5 class="mb-0 text-white">Ronald Rubén S. C.</h5>
                          <span>Emprendedor</span>
                        </div>
                        <i class="fas fa-quote-right text-white"></i>
                      </div>
                      <div class="client-say text-white">
                        <p>
                          Buen instrumento de compra y venta, lo recomiendo 👍
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template carouselSlide>
                  <div class="item">
                    <div class="testimonial-quote-wrap">
                      <div class="media author-info mb-3">
                        <div class="author-img mr-3">
                          <img
                            src="assets/img/500x500b.png"
                            alt="client"
                            class="img-fluid"
                          />
                        </div>
                        <div class="media-body text-white">
                          <h5 class="mb-0 text-white">Lutgatda C.</h5>
                          <span>Negociante</span>
                        </div>
                        <i class="fas fa-quote-right text-white"></i>
                      </div>
                      <div class="client-say text-white">
                        <p>Buen producto 👌.</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div class="item">
                    <div class="testimonial-quote-wrap">
                      <div class="media author-info mb-3">
                        <div class="author-img mr-3">
                          <img
                            src="assets/img/500x500b.png"
                            alt="client"
                            class="img-fluid"
                          />
                        </div>
                        <div class="media-body text-white">
                          <h5 class="mb-0 text-white">Claudia Q.</h5>
                          <span>Emprendedora</span>
                        </div>
                        <i class="fas fa-quote-right text-white"></i>
                      </div>
                      <div class="client-say text-white">
                        <p>
                          Me gusta el servicio, me respondieron bastante rapido
                          cuando escribí al whatsapp.
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--pricing section start-->
    <section id="pricing" class="pricing-section ptb-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9 col-lg-8">
            <div class="section-heading text-center mb-4">
              <h2>Nuestros precios</h2>
              <p>Primer mes sin costo.</p>
            </div>
          </div>
        </div>
        <div
          class="row align-items-center justify-content-md-center justify-content-center"
        >
          <!--<div class="col-12">
                        <div class="d-flex justify-content-center text-center">
                            <label class="pricing-switch-wrap">
                                <span class="beforeinput year-switch text-success">
                                Mensual
                            </span>
                                <input type="checkbox" class="d-none" id="js-contcheckbox">
                                <span class="switch-icon"></span>
                                <span class="afterinput year-switch">
                                    Anual
                            </span>
                            </label>
                        </div>
                    </div>-->
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="text-center bg-white single-pricing-pack mt-4">
              <div class="price-img pt-4">
                <img
                  src="assets/img/184x168.png"
                  alt="price"
                  width="120"
                  class="img-fluid"
                />
              </div>
              <div class="py-4 border-0 pricing-header">
                <div
                  class="price text-center mb-0 monthly-price color-secondary"
                  style="display: block"
                >
                  S/ 5<span>.00</span>
                </div>
                <div
                  class="price text-center mb-0 yearly-price color-secondary"
                  style="display: none"
                >
                  S/ 5<span>.00</span>
                </div>
              </div>
              <div class="price-name">
                <h5 class="mb-0">Iniciando</h5>
              </div>
              <div class="pricing-content">
                <ul class="list-unstyled mb-4 pricing-feature-list">
                  <li><span>Catálogo</span> web de 50 productos</li>
                  <li><span>Vende en internet con tu propio carrito</span></li>
                  <li class="text-deem">
                    <span>Punto de venta en</span> tu tienda
                  </li>
                  <li class="text-deem">
                    <span>Periodo de prueba un mes</span>
                  </li>
                </ul>
                <div style="margin: 20%">
                  <a
                    href="https://play.google.com/store/apps/details?id=infinitodhd.conruda.services"
                    target="_blank"
                    class="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                    style="border: solid 1px #00a859"
                  >
                    <span
                      class="fab fa-google-play icon-size-sm mr-3 color-primary"
                    ></span>
                    <div class="download-text text-center">
                      <small>Descarganos en</small>
                      <h5 class="mb-0">Google Play</h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div
              class="popular-price bg-white text-center single-pricing-pack mt-4"
            >
              <div class="price-img pt-4">
                <img
                  src="assets/img/184x168b.png"
                  alt="price"
                  width="120"
                  class="img-fluid"
                />
              </div>
              <div class="py-4 border-0 pricing-header">
                <div
                  class="price text-center mb-0 monthly-price color-secondary"
                  style="display: block"
                >
                  S/ 20<span>.00</span>
                </div>
                <div
                  class="price text-center mb-0 yearly-price color-secondary"
                  style="display: none"
                >
                  S/ 20<span>.00</span>
                </div>
              </div>
              <div class="price-name">
                <h5 class="mb-0">Mediano</h5>
              </div>
              <div class="pricing-content">
                <ul class="list-unstyled mb-4 pricing-feature-list">
                  <li><span>Catálogo</span> web de 250 productos</li>
                  <li><span>Vende en internet con tu propio carrito</span></li>
                  <li class="text-deem">
                    <span>Punto de venta en</span> tu tienda
                  </li>
                  <li class="text-deem">Reporte producto estrella</li>
                  <li class="text-deem">Reporte productos para comprar</li>
                  <li class="text-deem">
                    <span>Periodo de prueba un mes</span>
                  </li>
                </ul>
                <div style="margin: 20%">
                  <a
                    href="https://play.google.com/store/apps/details?id=infinitodhd.conruda.services"
                    target="_blank"
                    class="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                    style="border: solid 1px #00a859"
                  >
                    <span
                      class="fab fa-google-play icon-size-sm mr-3 color-primary"
                    ></span>
                    <div class="download-text text-center">
                      <small>Descarganos en</small>
                      <h5 class="mb-0">Google Play</h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="text-center bg-white single-pricing-pack mt-4">
              <div class="price-img pt-4">
                <img
                  src="assets/img/191x165.png"
                  alt="price"
                  width="120"
                  class="img-fluid"
                />
              </div>
              <div class="py-4 border-0 pricing-header">
                <div
                  class="price text-center mb-0 monthly-price color-secondary"
                  style="display: block"
                >
                  S/ ?<span>.00</span>
                </div>
                <div
                  class="price text-center mb-0 yearly-price color-secondary"
                  style="display: none"
                >
                  $ ?<span>.00</span>
                </div>
              </div>
              <div class="price-name">
                <h5 class="mb-0">Empresarial</h5>
              </div>
              <div class="pricing-content">
                <ul class="list-unstyled mb-4 pricing-feature-list">
                  <li>
                    El costo es deacuerdo al tamaño de su empresa y volumen de
                    ventas
                  </li>
                  <li><span>Catálogo</span> ilimitado</li>
                  <li><span>Vende en internet con tu propio carrito</span></li>
                  <li class="text-deem">
                    <span>Punto de venta en</span> tu tienda
                  </li>
                  <li class="text-deem">Reporte producto estrella</li>
                  <li class="text-deem">Reporte productos para comprar</li>
                  <li class="text-deem">
                    <span>Periodo de prueba un mes</span>
                  </li>
                </ul>
                <div style="margin: 20%">
                  <a
                    href="https://play.google.com/store/apps/details?id=infinitodhd.conruda.services"
                    target="_blank"
                    class="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
                    style="border: solid 1px #00a859"
                  >
                    <span
                      class="fab fa-google-play icon-size-sm mr-3 color-primary"
                    ></span>
                    <div class="download-text text-center">
                      <small>Descarganos en</small>
                      <h5 class="mb-0">Google Play</h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="support-cta text-center mt-5">
              <h5 class="mb-1">
                <span class="ti-headphone-alt color-primary mr-3"></span>Siempre
                estamos atentos a ayudarte
              </h5>
              <p>
                Tienes preguntas?
                <a
                  href="https://api.whatsapp.com/send/?phone=51956711403&text=Me%20interesa%20ConRuda%20cuentame%20m%C3%A1s"
                  target="_blank"
                >
                  Mandanos un mensaje al whatsapp haciendo click aquí o llamanos
                  al</a
                >
                <a href="#">+51 956711403</a> te respondemos al toque.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!--footer section start-->
  <!--when you want to remove subscribe newsletter container then you should remove .footer-with-newsletter class-->
  <footer class="footer-1 gradient-bg ptb-60 footer-without-newsletter">
    <!--
      
    <div class="container">
      <div class="row newsletter-wrap primary-bg rounded shadow-lg p-5">
        <div class="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
          <div class="newsletter-content text-white">
            <h3 class="mb-0 text-white">Subscribete a nuestras noticias</h3>
            <p class="mb-0">
              Te mandaremos artículos de negocio y muchos tips para mejorar.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-5">
          <form class="newsletter-form position-relative">
            <input
              type="text"
              class="input-newsletter form-control"
              placeholder="Escribe tu correo "
              name="email"
              required=""
              autocomplete="off"
            />
            <button type="submit" class="disabled">
              <i class="fas fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </div>
    </div>-->

    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
          <a href="#" class="navbar-brand mb-2">
            <img
              src="assets/img/fcm_push_icon.png"
              alt="logo"
              class="img-fluid"
            />
          </a>
          <br />
          <p>
            Échale ConRuda a tu negocio para la suerte y únete para que todos
            podamos ser parte del crecimiento digital..
          </p>
          <div
            class="list-inline social-list-default background-color social-hover-2 mt-2"
          >
            <li class="list-inline-item">
              <a
                class="facebook"
                href="https://www.facebook.com/ConRuda"
                target="_blank"
                ><i class="fab fa-facebook"></i
              ></a>
            </li>
            <!-- <li class="list-inline-item"><a class="twitter" href="#"><i class="fab fa-twitter"></i></a></li>
                        <li class="list-inline-item"><a class="youtube" href="#"><i class="fab fa-youtube"></i></a></li>
                        <li class="list-inline-item"><a class="linkedin" href="#"><i class="fab fa-linkedin-in"></i></a></li>-->
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="row mt-0">
            <div
              class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0"
            >
              <h6 class="text-uppercase">Products</h6>
              <ul>
                <li>
                  <a href="#pricing">Precio</a>
                </li>
              </ul>
            </div>
            <div
              class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0"
            >
              <h6 class="text-uppercase">Compañia</h6>
              <ul>
                <li>
                  <a href="https://infinitodhd.com" target="_blank">Infinito DHD</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3">
              <h6 class="text-uppercase">Soporte</h6>
              <ul>
                <li>
                  <a href="mailto:info@conruda.com">Escribenos</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end of container-->
  </footer>
  <!--footer bottom copyright start-->
  <div class="footer-bottom py-3 gray-light-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-7">
          <div class="copyright-wrap small-text">
            <p class="mb-0">&copy; Infinito DHD, All rights reserved</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-5">
          <div class="terms-policy-wrap text-lg-right text-md-right text-left">
            <ul class="list-inline">
              <li class="list-inline-item">
                <a class="small-text" href="#">Terms</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--footer bottom copyright end-->
  <!--footer section end-->
  <!--scroll bottom to top button start-->
  <div
    class="scroll-top scroll-to-target white-bg text-white"
    data-target="html"
    [ngClass]="{ open: scrollvalueToTarget }"
  >
    <a
      href="https://play.google.com/store/apps/details?id=infinitodhd.conruda.services"
      target="_blank"
      class="d-flex align-items-center app-download-btn btn btn-white btn-rounded"
      style="border: solid 3px #00a859; background-color: white"
    >
      <span class="fab fa-google-play icon-size-sm mr-3 color-primary"></span>
      <div class="download-text text-left">
        <small>Descarganos en</small>
        <h5 class="mb-0">Google Play</h5>
      </div>
    </a>
  </div>

  <!--scroll bottom to top button end-->
  <!--build:js-->
  <script src="assets/js/vendors/jquery-3.5.1.min.js"></script>
  <script src="assets/js/vendors/popper.min.js"></script>
  <script src="assets/js/vendors/bootstrap.min.js"></script>
  <script src="assets/js/vendors/jquery.easing.min.js"></script>
  <script src="assets/js/vendors/owl.carousel.min.js"></script>
  <script src="assets/js/vendors/countdown.min.js"></script>
  <script src="assets/js/vendors/jquery.waypoints.min.js"></script>
  <script src="assets/js/vendors/jquery.rcounterup.js"></script>
  <script src="assets/js/vendors/magnific-popup.min.js"></script>
  <script src="assets/js/vendors/validator.min.js"></script>
  <script src="assets/js/app.js"></script>
  <!--endbuild-->
</body>
