import { CatalogingActionTypes, CatalogingAction } from '../actions/cataloging.actions';
import { Product } from '../../../../models/app.models';
import { isNgTemplate } from '@angular/compiler';


export interface CatalogingState {
  list: any,
  cart: any,
  countTotalElements:number,
  totalPrice:number,
  totalCost:number,
  elementsReturned:number,
  loading: boolean,
  error: Error,
  message:string

}


const initialState: CatalogingState = {
  list: [],
  cart: [],
  elementsReturned:-1,
  loading: false,
  error: undefined,
  message:'',
  countTotalElements:0,
  totalPrice:0,
  totalCost:0,
};

export function CatalogingReducer(state: CatalogingState = initialState, action: CatalogingAction) {

  console.log("CatalogingReducer[action]: ", action );
  

  switch (action.type) {
    case CatalogingActionTypes.CLEAR_ELEMENTS_CART:{
      return {
        ...state,
        cart: [],
        loading: false,
        countTotalElements:0,
        totalPrice:0,          
        totalCost:0,          
        error: undefined,
        message:''
      }
    } 
    //---reset status---//
    case CatalogingActionTypes.RESET_INITIAL_CATALOGING:{
console.log("URRAARA");

      return {
        list: [],
        cart: [],
        elementsReturned:-1,
        loading: false,
        error: undefined,
        message:'',
        countTotalElements:0,
        totalPrice:0,
        totalCost:0,
      }
    }

    //------------------//
    case CatalogingActionTypes.INSERT_ELEMENT_CART:{

      console.log("CatalogingActionTypes[STATE]: ", state  );
        console.log("ACtion insert: ", action );
        

      //let elementsCartForInsert=state.cart.filter(item => item.id == action.product.id  );
      let elementsCartForInsert = [];
      state.cart.forEach(element => {
          if( element.id == action.product.id && element.codsiz == action.product.codsiz  ){
            elementsCartForInsert.push(element)
          }
      });
      console.log("elementsCartForInsert[filter]: ", elementsCartForInsert );
      

      let elementsOfCard=0;
      if( elementsCartForInsert.length>0 ){
        elementsOfCard=elementsCartForInsert[0].cartCount;
      }
      
      let eInsertElement=action.product;
      let newCounterCartInsert:number=eInsertElement.catalogCount;
      let disponibilidadReal=eInsertElement.availibilityCount-elementsOfCard;
      
      if(disponibilidadReal<newCounterCartInsert){//no cubre disponibilidad
        let mensajeErrorInsertElementCart='';
        if(disponibilidadReal>0){
          mensajeErrorInsertElementCart='Solo nos quedan: '+disponibilidadReal+' solo esos podrias agregar';
        }
        else if(disponibilidadReal<=0){
          mensajeErrorInsertElementCart='No nos queda más de ese producto en stock';
        }
        let errorInsertElementCart=new Error(mensajeErrorInsertElementCart);
        return {
          ...state,
          error:errorInsertElementCart,
          message:''
        }
      }
      else if(elementsCartForInsert.length>0){//disponibilidad parcial
        let elementsCartForUpdate=elementsCartForInsert[0];
        let indexCartUpdate=0
          for(let product of state.cart){
            if(product.id==action.product.id){
              break;
            }
            indexCartUpdate++;
          }
          let newCartCountForUpdate=action.product.catalogCount+elementsOfCard;
          let productMutCartUpdate= new Product(
            elementsCartForUpdate.id,
            elementsCartForUpdate.name,
            elementsCartForUpdate.images,
            elementsCartForUpdate.oldPrice,
            elementsCartForUpdate.newPrice,
            elementsCartForUpdate.cost,
            elementsCartForUpdate.discount,
            elementsCartForUpdate.ratingsCount,
            elementsCartForUpdate.ratingsValue,
            elementsCartForUpdate.description,
            elementsCartForUpdate.availibilityCount,
            elementsCartForUpdate.availibilityReal,
            newCartCountForUpdate,
            1,
            elementsCartForUpdate.color,
            elementsCartForUpdate.size,
            elementsCartForUpdate.codsiz,
            elementsCartForUpdate.weight,
            elementsCartForUpdate.categoryId,
            elementsCartForUpdate.flagOffer,
            elementsCartForUpdate.origPrice
          );

         /*  let arrayTempCartUpdate=state.cart.filter(item => item.id != action.product.id).slice();
          arrayTempCartUpdate.splice(indexCartUpdate,0,productMutCartUpdate); */

          let arrayTempCartUpdate = [];
          state.cart.forEach(element => {
              if( element.id == action.product.id && element.codsiz == action.product.codsiz ){
                arrayTempCartUpdate.push( productMutCartUpdate )
              }else{
                arrayTempCartUpdate.push(element)
              }
          });


          let countTotalElements=0;
          let totalPriceElements=0;
          let totalCostElements=0;
          for (let obj of arrayTempCartUpdate){
            console.log("arrayTempCartUpdate-reducer: ", arrayTempCartUpdate );
            
            countTotalElements+=obj.cartCount;
            totalPriceElements+=obj.cartCount*obj.newPrice;
            totalCostElements+=obj.cartCount*obj.cost;
          }
          return {
            ...state,
            cart: arrayTempCartUpdate,
            countTotalElements:countTotalElements,
            totalPrice:totalPriceElements,  
            totalCost:totalCostElements,  
            loading: true,
            error:'',
            message:'Se agrego el producto al carrito'
          }
      }
      else{//disponibilidad
        let productInsertElement= new Product(
          eInsertElement.id,
          eInsertElement.name,
          eInsertElement.images,
          eInsertElement.oldPrice,
          eInsertElement.newPrice,
          eInsertElement.cost,
          eInsertElement.discount,
          eInsertElement.ratingsCount,
          eInsertElement.ratingsValue,
          eInsertElement.description,
          eInsertElement.availibilityCount,
          eInsertElement.availibilityReal,
          newCounterCartInsert,
          1,
          eInsertElement.color,
         
          eInsertElement.size,
          eInsertElement.codsiz,
          eInsertElement.weight,
          eInsertElement.categoryId,
          eInsertElement.flagOffer,
          eInsertElement.origPrice,
        );
        let countTotalElements=0;
        let totalPriceElements=0;
        let totalCostElements=0;

        for (let obj of state.cart.concat( productInsertElement )){ //añadiendo el nuevo prod a cart
          countTotalElements+=obj.cartCount;
          totalPriceElements+=obj.cartCount*obj.newPrice;
          totalCostElements+=obj.cartCount*obj.cost;
          
        }
        return {
          ...state,
          cart:  state.cart.concat(productInsertElement),
          loading: false,
          error:'',
          countTotalElements:countTotalElements,
          totalPrice:totalPriceElements,
          totalCost:  totalCostElements,
          message:'Se agrego el producto al carrito'
        }
      }
    } 
    case CatalogingActionTypes.DECREMENT_COUNTER_ELEMENT_CART:{
      //let elementCounterDecrement=state.cart.filter(item => item.id == action.id)[0];
 //let elementCounterIncrement=state.cart.filter(item => item.id == action.id)[0];
      let prod:Product[] = [];


      console.log("StateCART(1): ", state.cart );
      

      state.cart.forEach(element => {
          if( element.id == action.id && element.codsiz == action.codsiz  ){
            prod.push(element)
          }
      });
      let elementCounterDecrement = prod[0];



      if (elementCounterDecrement.cartCount-1==0){
        
        let countTotalElementsDecrementCart=0;
        let totalPriceElementsDecrementCart=0;
        let totalCostElements=0;

        let decrementProduct =  state.cart.filter(item => {

          if( item.id == action.id && item.codsiz == action.codsiz ){
            return false;
          }

          return true;
          
        }) 

        for (let obj of decrementProduct){
          countTotalElementsDecrementCart+=obj.cartCount;
          totalPriceElementsDecrementCart+=obj.cartCount*obj.newPrice;
          totalCostElements+=obj.cartCount*obj.cost;
        }

        let cinco  = decrementProduct

        console.log("Cinco: ", cinco );
        


        return {
          ...state,
          cart: decrementProduct,
          countTotalElements:countTotalElementsDecrementCart,
          totalPrice:totalPriceElementsDecrementCart, 
          totalCost:  totalCostElements,
          loading: false,
          message:'Se retiro el producto del carrito',
          error:undefined

        }
      }

      let newCounterCartDecrement:number=elementCounterDecrement.cartCount-1;
      let productMutCartDecrement= new Product(
        elementCounterDecrement.id,
        elementCounterDecrement.name,
        elementCounterDecrement.images,
        elementCounterDecrement.oldPrice,
        elementCounterDecrement.newPrice,
        elementCounterDecrement.cost,
        elementCounterDecrement.discount,
        elementCounterDecrement.ratingsCount,
        elementCounterDecrement.ratingsValue,
        elementCounterDecrement.description,
        elementCounterDecrement.availibilityCount,
        elementCounterDecrement.availibilityReal,
        newCounterCartDecrement,
        1,
        elementCounterDecrement.color,
        elementCounterDecrement.size,
        elementCounterDecrement.codsiz,
        elementCounterDecrement.weight,
        elementCounterDecrement.categoryId,
        elementCounterDecrement.flagOffer,
        elementCounterDecrement.origPrice
      );
      let indexCartDecrement=0
      for(let product of state.cart){
        if(product.id==action.id){
          break;
        }
        indexCartDecrement++;
      }

              
        //let arrayTempCartDecrement=state.cart.filter(item => item.id != action.id).slice();
        let arrayTempCartDecrement = [];
      //let arrayTempCartDecrement=state.cart.filter(item => item.id != action.id).slice();
      //mod for size
      state.cart.forEach(element => {
          if( element.id == action.id && element.codsiz == action.codsiz ){
            arrayTempCartDecrement.push( productMutCartDecrement )
          }else{
            arrayTempCartDecrement.push( element )
          }
      });



        //arrayTempCartDecrement.splice(indexCartDecrement,0,productMutCartDecrement);

        let countTotalElementsCartDecrement=0;
        let totalPriceElementsCartDecrement=0;
        let totalCostElementCartDecrement=0;
        for (let obj of arrayTempCartDecrement){
          countTotalElementsCartDecrement+=obj.cartCount;
          totalPriceElementsCartDecrement+=obj.cartCount*obj.newPrice;
          totalCostElementCartDecrement+=obj.cartCount*obj.cost
        }

        return {
          ...state,
          cart: arrayTempCartDecrement,
          countTotalElements:countTotalElementsCartDecrement,
          totalPrice:totalPriceElementsCartDecrement, 
          totalCost:totalCostElementCartDecrement,
          message:'Se quito el producto al carrito',
          error:undefined,
          loading: false
        }
    }
    case CatalogingActionTypes.INCREMENT_COUNTER_ELEMENT_CART:{

      //let elementCounterIncrement=state.cart.filter(item => item.id == action.id)[0];
      let prod:Product[] = [];
      state.cart.forEach(element => {
          if( element.id == action.id && element.codsiz == action.codsiz  ){
            prod.push(element)
          }
      });
      let elementCounterIncrement = prod[0];

      let newCounterCartIncrement:number=elementCounterIncrement.cartCount+action.quanty;
      if(newCounterCartIncrement>elementCounterIncrement.availibilityCount){
        let errorInsertElementCart=new Error('No puedes insertar mas de '+ elementCounterIncrement.availibilityCount.toString()+', no hay mas stock');
        return {
          ...state,
          loading: false,
          error:errorInsertElementCart,
          message:''
        }
      }
      let productMutCartIncrement= new Product(
        elementCounterIncrement.id,
        elementCounterIncrement.name,
        elementCounterIncrement.images,
        elementCounterIncrement.oldPrice,
        elementCounterIncrement.newPrice,
        elementCounterIncrement.cost,
        elementCounterIncrement.discount,
        elementCounterIncrement.ratingsCount,
        elementCounterIncrement.ratingsValue,
        elementCounterIncrement.description,
        elementCounterIncrement.availibilityCount,
        elementCounterIncrement.availibilityReal,
        newCounterCartIncrement,
        1,
        elementCounterIncrement.color,
        elementCounterIncrement.size,
        elementCounterIncrement.codsiz,
        elementCounterIncrement.weight,
        elementCounterIncrement.categoryId,
        elementCounterIncrement.flagOffer,
        elementCounterIncrement.origPrice
      );

      let indexCartIncrement=0
      for(let product of state.cart){
        if(product.id==action.id){
          break;
        }
        indexCartIncrement++;
      }
      
      let arrayTempCartIncrement = [];
      //let arrayTempCartIncrement=state.cart.filter(item => item.id != action.id).slice();
      //mod for size
      state.cart.forEach(element => {
          if( element.id == action.id && element.codsiz == action.codsiz ){
            arrayTempCartIncrement.push( productMutCartIncrement )
          }else{
            arrayTempCartIncrement.push( element )
          }
      });



      //arrayTempCartIncrement.splice(indexCartIncrement,0,productMutCartIncrement);
      let countTotalElementsIncrementCart=0;
      let totalPriceElementsIncrementCart=0;
      let totalCostElementsIncrementCart=0;
      for (let obj of arrayTempCartIncrement){
        countTotalElementsIncrementCart+=obj.cartCount;
        totalPriceElementsIncrementCart+=obj.cartCount*obj.newPrice;
        totalCostElementsIncrementCart+=obj.cartCount*obj.cost;
      }
      return {
        ...state,
        cart: arrayTempCartIncrement,
        countTotalElements:countTotalElementsIncrementCart,
        totalPrice:totalPriceElementsIncrementCart,
        totalCost:totalCostElementsIncrementCart,
        error:undefined, 
        message:'Se agrego el producto al carrito',
        loading: true
      }

    }         
    case CatalogingActionTypes.DELETE_ELEMENT_CART:{
      console.log("Delete element");
      
      let countTotalElementsDeleteCart=0;
      let totalPriceElementsDeleteCart=0;
      let totalCostElementsDeleteCart=0;
      

      /* for (let obj of state.cart.filter(item => item.id !== action.id)){

        countTotalElementsDeleteCart+=obj.cartCount;
        totalPriceElementsDeleteCart+=obj.cartCount*obj.newPrice;
        totalCostElementsDeleteCart+=obj.cartCount*obj.cost;
      } */

      let restCart=[];
      state.cart.forEach(element => {
          if( (element.id == action.id && element.codsiz == action.codsiz) ){


          }else{
            restCart.push( element )
            countTotalElementsDeleteCart+=element.cartCount;
            totalPriceElementsDeleteCart+=element.cartCount*element.newPrice;
            totalCostElementsDeleteCart+=element.cartCount*element.cost;

          }


      });




      return {
        ...state,
        cart: restCart,
        countTotalElements:countTotalElementsDeleteCart,
        totalPrice:totalPriceElementsDeleteCart, 
        totalCost:totalCostElementsDeleteCart, 
        loading: false,
        message:'Se retiro el producto del carrito',
        error:undefined
      }
    } 
    case CatalogingActionTypes.LOAD_INITIAL_CART:{
      return {
        ...state,
        loading: true
      }
    }
    case CatalogingActionTypes.LOAD_INITIAL_CART_SUCCESS:{
      let countTotalElementsInitialCart=0;
      let totalPriceElementsInitialCart=0;
      let totalCostElementsInitialCart=0;
      for (let obj of action.catalog){
        countTotalElementsInitialCart+=obj.cartCount;
        totalPriceElementsInitialCart+=obj.cartCount*obj.newPrice;
        totalCostElementsInitialCart+=obj.cartCount*obj.cost;
      }
      return {
        ...state,
        list: action.catalog ,
        countTotalElements:countTotalElementsInitialCart,
        totalPrice:totalPriceElementsInitialCart, 
        totalCost:totalCostElementsInitialCart, 
        elementsReturned:action.catalog.length,
        loading: false
      }
    }   
    case CatalogingActionTypes.LOAD_INITIAL_CART_FAILURE:{
      return {
        ...state,
        error: action.catalog
      }
    }
    case CatalogingActionTypes.DECREMENT_COUNTER_CATALOGING:{
      console.log("Decrement Counter");
      
      let ed=state.list.filter(item => item.id == Number.parseInt(action.catalog))[0];
      let newCounterD:number=ed.catalogCount-1;
      if (newCounterD<0){
        return {
          ...state,
          message:'No se puede quitar mas elementos',
          error:undefined,
          loading: false
        }
      }



      let productMutD= new Product(
        ed.id,
        ed.name,
        ed.images,
        ed.oldPrice,
        ed.newPrice,
        ed.cost,
        ed.discount,ed.ratingsCount,
        ed.ratingsValue,
        ed.description,ed.availibilityCount,
        ed.availibilityReal,ed.cartCount,
        newCounterD
        ,ed.color,ed.size, ed.codsiz ,ed.weight,
        ed.categoryId,
        ed.flagOffer,
        ed.origPrice
      );
      let indexD=0;
      for(let product of state.list){
        if(product.id==Number.parseInt(action.catalog)){
          break;
        }
        indexD++;
      }
      console.log(productMutD);
        let arrayTempD=state.list.filter(item => item.id != Number.parseInt(action.catalog)).slice();
        arrayTempD.splice(indexD,0,productMutD);
        return {
          ...state,
          list: arrayTempD,
          message:'',
          error:undefined,
          loading: true
      }
    }

    case CatalogingActionTypes.CHANGE_SIZE_PRODUCT:{

      console.log("change Size: ", action.catalog  );
      let i= 0;
      let productArr = [];
      console.log("state.list: ", state.list );
      state.list.forEach((element , index ) => {
        if( action.catalog.id == element.id ){
          i = index;
          console.log("indice: ", i );
          productArr.push( action.catalog )
        }else{
          productArr.push( element )
        }
      });
      console.log("productList ", productArr);

      return {
        ...state,
        list:productArr
      }
      break;
    }

    case CatalogingActionTypes.INCREMENT_COUNTER_CATALOGING:{
      console.log("increment Counter");
      

      console.log(action.catalog);
      let e=state.list.filter(item => item.id == Number.parseInt(action.catalog))[0];
      let newCounter:number=e.catalogCount+1;
      let productMut= new Product(
        e.id,
        e.name,
        e.images,
        e.oldPrice,
        e.newPrice,
        e.cost,
        e.discount,e.ratingsCount,
        e.ratingsValue,
        e.description,e.availibilityCount,
        e.availibilityReal,e.cartCount,
        newCounter
        ,e.color,e.size, e.codsiz,e.weight,
        e.categoryId,
        e.flagOffer,
        e.origPrice
      );
      let index=0;
      for(let product of state.list){
        if(product.id==Number.parseInt(action.catalog)){
          break;
        }
        index++;
      }
      console.log(productMut);
        let arrayTemp=state.list.filter(item => item.id != Number.parseInt(action.catalog)).slice();
        arrayTemp.splice(index,0,productMut);
        return {
          ...state,
          list: arrayTemp,
          loading: true
      }
    }
    case CatalogingActionTypes.LOAD_INITIAL_CATALOGING:{
      return {
        ...state,
        loading: true
      }
    }
    case CatalogingActionTypes.LOAD_INITIAL_CATALOGING_SUCCESS:{
      return {
        ...state,
        list: action.catalog.slice().sort((b,a) => a.availibilityReal-b.availibilityReal) ,
        //list: action.catalog ,
        elementsReturned:action.catalog.length,
        loading: false
      }
    }
    case CatalogingActionTypes.LOAD_INITIAL_CATALOGING_FAILURE:{
      return {
        ...state,
        error: action.catalog
      }
    }
    case CatalogingActionTypes.CLEAR_CATALOG:{
      return {
        ...state,
        list: [],
        elementsReturned:action.catalog.length,
        loading: false
        
      }
    }
    case CatalogingActionTypes.CLEAR_CATALOG_SUCCESS:{
      return {
        ...state,
        list: state.list,
        elementsReturned:action.catalog.length,
        loading: false  
      }
    }
    case CatalogingActionTypes.CLEAR_CATALOG_FAILURE:{
      return {
        ...state,
        loading: true
      }
    }
      
    case CatalogingActionTypes.LOAD_CATALOGING:{
      return {
        ...state,
        loading: true
      }
    }
      
    case CatalogingActionTypes.LOAD_CATALOGING_SUCCESS:{
      return {
        ...state,
        list: state.list.concat(action.catalog).sort((b,a) => a.availibilityReal -b.availibilityReal) ,
        elementsReturned:action.catalog.length,
        loading: false
      }
    }
      
    case CatalogingActionTypes.LOAD_CATALOGING_FAILURE: {
      return {
        ...state,
        error: action.catalog
      }
    }
      
    
    case CatalogingActionTypes.ADD_ITEM_CATALOG:
      return {
        ...state,
        loading: true
      }
    case CatalogingActionTypes.ADD_ITEM_CATALOG_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.catalog],
        loading: false
      };
    case CatalogingActionTypes.ADD_ITEM_CATALOG_FAILURE:
      return {
        ...state,
        error: action.catalog,
        loading: false
      };
    case CatalogingActionTypes.DELETE_ITEM_CATALOG:
      return {
        ...state,
        loading: true
      };
    case CatalogingActionTypes.DELETE_ITEM_CATALOG_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== Number.parseInt(action.catalog)),
        loading: false
      }
    case CatalogingActionTypes.DELETE_ITEM_CATALOG_FAILURE:
      return {
        ...state,
        error: action.catalog,
        loading: false
      };
    default:
      return state;
  }
}