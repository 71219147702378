<div *ngIf="isLoaded==false" class="100vw GreenDarkprimaryColorBG">
  <img src="assets/images/conrudapx.png" class="spinner" style="width: 100px; height:auto;">
  <span style="color: white;">Con Ruda</span>
  <img src="assets/images/spinner/spinner3.svg" class="spinner2" style="width: 100px; height:auto;">
  &nbsp;
</div>
<!--<alert type="success">
    <strong>Well done!</strong> You successfully read this important alert message.
  </alert>-->
<ngx-spinner></ngx-spinner>

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<a
*ngIf="currentComponent"

[href]='"https://api.whatsapp.com/send?" + "phone="+51+phone+"&text=Hola "+compName+", quisiera mas información."' class="float-wb" target="_blank" >
<i class="fa fa-whatsapp my-float"></i>
</a>
<div *ngIf="isLoaded==true"   >
  <router-outlet></router-outlet>
</div>

