import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  public API_URL: any;
  public app: any;
  public TIEMPOS: any;
  public CONSTANTES: any[];
  public MENU: any[];

  constructor() { 
    this.app = {
      name: 'Olimpo',
      description: 'ecommerce',
      year: ((new Date()).getFullYear()),
      idcompany:null,
      idstore:241,
      itemPerQueryMobile:10,
      itemPerQueryWeb:10,
      version : '3.0.2',
      timeReviewVersion:'1',
      aplicacion : 'Olimpo-ecommerce',
      urlLogin : '#/',
      urlInicio : '#/',            
      cache : false,
      timeout : 60000,
      flag_aplicacion_web : '1',
      URL_DOMAIN_SERVICES : 'https://conruda.com/apiconruda/',//Web
      URL_DOMAIN_SHARED : 'https://conruda.com/apiconruda/',//Web
      URL_DOMAIN_CORE : 'https://conruda.com/apiconruda/',//Web
      URL_DOMAIN_CORE_CONRUD : 'https://conruda.com/apiconruda/',//Web
  };

    this.API_URL = (function(){
      return {
        'api_getStores': 'getStores',
        'api_getCompanyInfo': 'getCompanyById',
        'api_getMenu': 'getMenu',
        'api_getProductsForPanel': 'getProductsForPanel',
        'api_getProductsForCookie': 'getProductsForCookie/',
        'api_setHeaderSales': 'setHeaderSales/',
        'api_getDistricts': 'getDistricts/',
        'api_setSales': 'setSales/',
        'api_setDelivery': 'setDelivery/',
        'api_getServices': 'getServices/',
        'api_getLogin': 'getLogin/',
        'api_getVersion': 'getVersion/',
        'api_searchEmail': 'searchEmail/',
        'api_setSalesBatch': 'setSalesBatch/',
        'api_setHeaderAndSalesBath':'setHeaderAndSalesBath',
        'api_getPayMethodMobileConf':'getPayMethodMobileConf',
        'api_getCompanyById':'getCompanyById',
        'api_getAccountsByIdTypePayment':'getAccountsByIdTypePayment',

        'api_setNewCustomer':'setNewCustomer_getData',
        'api_getAuthClient':'getAuthClient',
        'api_getCustomersByID':'getCustomersByID',
        'api_updCustomer':'updCustomer_getData',
        'api_getHeaderSaleByCustomerId':'getHeaderSaleByCustomerId',
        'api_getSalesTable':'getSalesTable',
        'getDeliveryConf':'getDeliveryConf_getData',
        'api_getPayMethods':'getPayMethods',
        'api_getParameters':'getParameters',
        'api_getPayMethodByParameterCodPar':'getPayMethodByParameterCodPar',
        'api_getHeaderSale':'getHeaderSale',
        'api_getIdUniqueAndIdCounter':'getIdUniqueAndIdCounter',
      }
    })();
    this.TIEMPOS = (function(){
      return {
        'time_alert': 4000,
      }
    })();
    this.CONSTANTES = [];
    this.CONSTANTES['APLICACION'] = this.app;
    this.CONSTANTES['API_URL'] = this.API_URL;
    this.CONSTANTES['TIEMPOS'] = this.TIEMPOS;
  }
  getConstantesByDominioKey(dominio, key) {
    if (typeof this.CONSTANTES[dominio] !== 'undefined') {
      return (this.CONSTANTES[dominio])[key];
    }
  }



  setdataToCart(data){
    return new Observable<any>((obs) => { 
      console.log("setDataToCart: ", data );
      this.app.idcompany = data;
      obs.next( this.app );
    })
  }

  getdataToCart(){

    return this.app;

  }

  




}
