import { Action } from '@ngrx/store';
import { Product } from '../../../../models/app.models';

export enum CatalogingActionTypes {
  CLEAR_ELEMENTS_CART = '[CATALOGING] Clear elements cart',
  INCREMENT_COUNTER_ELEMENT_CART = '[CATALOGING] Increment counter element cart',
  DECREMENT_COUNTER_ELEMENT_CART = '[CATALOGING] Decrement counter element cart',
  LOAD_INITIAL_CART = '[CATALOGING] Load initial cart',
  LOAD_INITIAL_CART_SUCCESS = '[CATALOGING] Load initial cart Success',
  LOAD_INITIAL_CART_FAILURE = '[CATALOGING] Load initial cart Failure',
  INSERT_ELEMENT_CART = '[CATALOGING] Insert element cart',
  DELETE_ELEMENT_CART = '[CATALOGING] Delete element cart',
  INCREMENT_COUNTER_CATALOGING = '[CATALOGING] Increment counter cataloging',

  CHANGE_SIZE_PRODUCT = '[CATALOGING] change size of product',

  DECREMENT_COUNTER_CATALOGING = '[CATALOGING] Decrement counter cataloging',
  LOAD_INITIAL_CATALOGING = '[CATALOGING] Load initial cataloging',
  LOAD_INITIAL_CATALOGING_SUCCESS = '[CATALOGING] Load initial cataloging Success',
  LOAD_INITIAL_CATALOGING_FAILURE = '[CATALOGING] Load initial cataloging Failure',

  RESET_INITIAL_CATALOGING = '[CATALOGING] reset initial status of catalog',

  LOAD_CATALOGING = '[CATALOGING] Load cataloging',
  LOAD_CATALOGING_SUCCESS = '[CATALOGING] Load cataloging Success',
  LOAD_CATALOGING_FAILURE = '[CATALOGING] Load cataloging Failure',
  ADD_ITEM_CATALOG = '[CATALOGING] Add Item catalog',
  ADD_ITEM_CATALOG_SUCCESS = '[CATALOGING] Add item catalog Success',
  ADD_ITEM_CATALOG_FAILURE = '[CATALOGING] Add Item catalog Failure',
  DELETE_ITEM_CATALOG = '[CATALOGING] Delete Item catalog',
  DELETE_ITEM_CATALOG_SUCCESS = '[CATALOGING] Delete Item catalog Success',
  DELETE_ITEM_CATALOG_FAILURE = '[CATALOGING] Delete Item catalog Failure',
  CLEAR_CATALOG = '[CATALOGING] Clear catalog',
  CLEAR_CATALOG_SUCCESS = '[CATALOGING] Clear catalog Success',
  CLEAR_CATALOG_FAILURE = '[CATALOGING] Clear catalog Failure',
}

export class ClearElementsCartAction implements Action {
  readonly type = CatalogingActionTypes.CLEAR_ELEMENTS_CART;
  constructor() {}
}

//----------------------reset status-------------------//
export class resetCatalogingAction implements Action {
  readonly type = CatalogingActionTypes.RESET_INITIAL_CATALOGING;
  constructor() {
    console.log('RESETInitialCatalogingAction');
  }
}

//-----------------------------------------------------//
export class DecrementCounterElementCartAction implements Action {
  readonly type = CatalogingActionTypes.DECREMENT_COUNTER_ELEMENT_CART;
  constructor(public id: number, public codsiz: string) {}
}
export class IncrementCounterElementCartAction implements Action {
  readonly type = CatalogingActionTypes.INCREMENT_COUNTER_ELEMENT_CART;
  constructor(
    public id: number,
    public quanty: number,
    public codsiz: string
  ) {}
}
export class InsertElementCartAction implements Action {
  readonly type = CatalogingActionTypes.INSERT_ELEMENT_CART;

  constructor(public product: Product) {}
}
export class DeleteElementCartAction implements Action {
  readonly type = CatalogingActionTypes.DELETE_ELEMENT_CART;
  constructor(public id: number, public codsiz: string) {}
}
export class LoadInitialCartAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_INITIAL_CART;
  constructor(public catalog: string) {}
}
export class LoadInitialCartSuccessAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_INITIAL_CART_SUCCESS;
  constructor(public catalog: Array<Product>) {}
}
export class LoadInitialCartFailureAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_INITIAL_CART_FAILURE;
  constructor(public catalog: string) {}
}
export class DecrementCounterCatalogingAction implements Action {
  readonly type = CatalogingActionTypes.DECREMENT_COUNTER_CATALOGING;
  constructor(public catalog: string) {}
}

export class IncrementCounterCatalogingAction implements Action {
  readonly type = CatalogingActionTypes.INCREMENT_COUNTER_CATALOGING;
  constructor(public catalog: string) {}
}

//--------------------------change size product----------------------------//
export class ChangeSizeCatalogingAction implements Action {
  readonly type = CatalogingActionTypes.CHANGE_SIZE_PRODUCT;
  constructor(public catalog: any) {}
}
//--------------------------------------------------------------------------//

export class LoadInitialCatalogingAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_INITIAL_CATALOGING;
  constructor(public catalog: any) {
    console.log('LoadInitialCatalogingAction', this.catalog);
  }
}

export class LoadInitialCatalogingSuccessAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_INITIAL_CATALOGING_SUCCESS;
  constructor(public catalog: Array<Product>) {}
}
export class LoadInitialCatalogingFailureAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_INITIAL_CATALOGING_FAILURE;
  constructor(public catalog: string) {}
}

export class ClearCatalogingAction implements Action {
  readonly type = CatalogingActionTypes.CLEAR_CATALOG;
  constructor(public catalog: string) {}
}
export class ClearCatalogingSuccessAction implements Action {
  readonly type = CatalogingActionTypes.CLEAR_CATALOG_SUCCESS;
  constructor(public catalog: string) {}
}
export class ClearCatalogingFailureAction implements Action {
  readonly type = CatalogingActionTypes.CLEAR_CATALOG_FAILURE;
  constructor(public catalog: string) {}
}

export class LoadCatalogingAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_CATALOGING;
  constructor(public catalog: any) {}
}

export class LoadCatalogingSuccessAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_CATALOGING_SUCCESS;
  constructor(public catalog: Array<Product>) {}
}
export class LoadCatalogingFailureAction implements Action {
  readonly type = CatalogingActionTypes.LOAD_CATALOGING_FAILURE;
  constructor(public catalog: string) {}
}

export class AddItemCatalogAction implements Action {
  readonly type = CatalogingActionTypes.ADD_ITEM_CATALOG;
  constructor(public catalog: Product) {}
}
export class AddItemCatalogSuccessAction implements Action {
  readonly type = CatalogingActionTypes.ADD_ITEM_CATALOG_SUCCESS;
  constructor(public catalog: Product) {}
}
export class AddItemCatalogFailureAction implements Action {
  readonly type = CatalogingActionTypes.ADD_ITEM_CATALOG_FAILURE;
  constructor(public catalog: Error) {}
}

export class DeleteItemCatalogAction implements Action {
  readonly type = CatalogingActionTypes.DELETE_ITEM_CATALOG;

  constructor(public catalog: string) {}
}

export class DeleteItemCatalogSuccessAction implements Action {
  readonly type = CatalogingActionTypes.DELETE_ITEM_CATALOG_SUCCESS;

  constructor(public catalog: string) {}
}
export class DeleteItemCatalogFailureAction implements Action {
  readonly type = CatalogingActionTypes.DELETE_ITEM_CATALOG_FAILURE;

  constructor(public catalog: string) {}
}

export type CatalogingAction =
  | AddItemCatalogAction
  | LoadInitialCatalogingAction
  | LoadInitialCatalogingFailureAction
  | LoadInitialCatalogingSuccessAction
  | ChangeSizeCatalogingAction
  | AddItemCatalogSuccessAction
  | AddItemCatalogFailureAction
  | DeleteItemCatalogAction
  | DeleteItemCatalogSuccessAction
  | DeleteItemCatalogFailureAction
  | LoadCatalogingAction
  | LoadCatalogingFailureAction
  | LoadCatalogingSuccessAction
  | ClearCatalogingAction
  | ClearCatalogingFailureAction
  | ClearCatalogingSuccessAction
  | IncrementCounterCatalogingAction
  | DecrementCounterCatalogingAction
  | DecrementCounterElementCartAction
  | IncrementCounterElementCartAction
  | InsertElementCartAction
  | DeleteElementCartAction
  | LoadInitialCartAction
  | LoadInitialCartSuccessAction
  | LoadInitialCartFailureAction
  | resetCatalogingAction
  | ClearElementsCartAction;
