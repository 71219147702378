<div class="modal-header"
[ngClass]="{'bgRed':verifyColor()}"
>
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Por favor recuerda que:<br>
    -No recibimos efectivo, nos cuidamos para cuidarte.<br><br>
    Nuestros medios de pago son: <br>
    - Plin, Yape
    - Transferencia bcp, interbank, continental, Scotiabank<br>
    <br>
    Nuestras zonas de reparto:<br>
    - Cerro colorado (parte baja)<br>
    - Cayma (parte baja)<br>
    - Umacollo<br>
    - Yanahuara<br>
    - Sachaca<br>
    - Cercado<br>
    - Hunter (parte baja)<br>
    - José Luis Bustamante y Rivero<br>
    - Paucarpata<br>
    - Mariano Melgar<br>
    - Miraflores (parte baja)<br>
    - Alto Selva Alegre (parte baja)<br>
    - Socabaya (parte baja)<br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default buttonRounded"  (click)="bsModalRef.hide()">Listo quiero comprar</button>
  </div>