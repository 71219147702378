import { ChangeDetectorRef, Component } from '@angular/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AppSettingsService } from './global/app-settings.service';
import { AppService } from './core/app.service';
import { AppQuerysService } from './core/app-querys.service';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin } from 'rxjs';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, timeout } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalInitialComponent } from './pages/shared/modal-initial/modal-initial.component';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
} from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from './models/app-state.model';
import * as actionsCataloging from './pages/catalog/store/actions/cataloging.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  bsModalRef: BsModalRef;
  public isLoaded: boolean = false;
  //title = 'Tienda sol';
  public requests: any = [];
  public responses: any = [];
  public phone: string;
  public compName: string;
  public currentComponent = false;
  private component= 'CatalogComponent'
  constructor(
    public appSettings: AppSettingsService,
    private appService: AppService,
    private appQuerys: AppQuerysService,
    public modalService: BsModalService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
  ) {}

  openInitialMessage(msg: any, color: any) {
    const initialState = {
      title: msg,
      color: color,
    };
    const config = {
      animated: false,
      backdrop: true,
      ignoreBackdropClick: false,
    };
    //this.bsModalRef = this.modalService.show(ModalComponent, {initialState,backdrop: true,ignoreBackdropClick: false});
    this.bsModalRef = this.modalService.show(
      ModalInitialComponent,
      Object.assign({ initialState }, config, {})
    );
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  ngOnInit() {
    console.log('app.components');
    this.appQuerys.getClickEvent().subscribe((compo) => {
      console.log('__APP.COMPONENT__: ', compo);
      this.component = compo
      if (compo == 'CatalogComponent' || compo == 'CheckoutComponent') {
        this.currentComponent = true;
        this.cdr.detectChanges();
      } else {
        this.currentComponent = false;
        this.cdr.detectChanges();
      }

      if( compo == 'CatalogComponent' ){

      }



    });
    //-------------------obtner el param-----------------//


    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          console.log("ROUUTE: ", route );
          
          return route;
        }),
        mergeMap((route) => route.paramMap),
        tap((d) => {
          console.log('parametros: ', d);
        })
      )
      .subscribe((param: any) => {
        console.log('DDD: ', param);
        let idCompany = param.params.idcompany;
        console.log('IDCOMANY: ', idCompany);
        console.log("compo: ", this.component );
        let currentid = localStorage.getItem(environment.idcompanyweb)
        let currentLocation = window.location.href
        console.log("CurrentLocation: ", currentLocation );
                  
            this.appSettings.app.idcompany = idCompany;
          this.appService.pageToLoad = 1;
        if( currentLocation.includes('catalog') && currentid != idCompany ){
            
          console.log("dentro de router ");
          
            this.store.dispatch( new actionsCataloging.resetCatalogingAction() );
            this.appService.cleanCookies();
            localStorage.removeItem(environment.idClient)
            localStorage.setItem(environment.idcompanyweb, idCompany )
            //-----------------------------------------//
            this.getInfo(localStorage.getItem(environment.idcompanyweb));
           
        }

         
        //------------reset some values------------//

        

      });

    this.getInfo(localStorage.getItem(environment.idcompanyweb));
    //this.openInitialMessage('Importante', '123');
    //---------------------------------------------------//
  }

  getInfo(idcompany: string) {
    console.log("GET INFO: ");
    this.requests = [];
    this.requests.push(this.appQuerys.getCompanyInfo({ IDCOMPANY: idcompany })); //push 1
    //let requestMenu=idcompany+'/1'
    let requestMenu = '381' + '/1';
    this.requests.push(this.appQuerys.getMenu({ IDCOMPANY: idcompany })); //push 2
    let requestDistrict = '1/' + idcompany;
   // this.requests.push(this.appQuerys.getDistrict(requestDistrict)); //
    let requestServices = idcompany;
    //this.requests.push(this.appQuerys.getServices(requestServices)); //

    let stringCardList = this.cookieService.get('cartList'); //
    console.log('CookieService ( stringCardList ) : ', stringCardList);
    let stringProduct: string = '';

    if (stringCardList != '') {
      let arrayCardList = stringCardList.split('A');
      let countArrayListProduct: number = 0;

      for (let cartElement of arrayCardList) {
        if (countArrayListProduct > 0) {
          stringProduct += ',';
        }

        let cartElementCom = cartElement.split('-');
        console.log('cartElementCom: ', cartElementCom);

        console.log('cartElement[1]: ', cartElementCom[1]);

        let cantidad_codsiz = cartElementCom[1].split(':');
        console.log('cantidad_codsiz: ', cantidad_codsiz);

        let cantidad = cantidad_codsiz[0];
        let codsiz = cantidad_codsiz[1]; //la cantidad no interesa (preguntar como funcion el getProductsForCookie)

        if (codsiz) {
          stringProduct += cartElementCom[0] + ':' + codsiz;
        } else {
          stringProduct += cartElementCom[0];
        }

        countArrayListProduct++;
      }
    } else {
      stringProduct = '0';
    }

    let requestProductsForCookie = idcompany + '/' + stringProduct;
    this.requests.push(
      this.appQuerys.getProductsForCookie(requestProductsForCookie)
    ); // push 2
    
    forkJoin(this.requests).subscribe(
      (data: any) => {
        this.responses = data;
        console.log("__DATA__: ", data ) ;
        
        let statusResponses: boolean = true;
        if(typeof data[0].data[0]!='undefined'){
          this.phone = data[0].data[0].C_PHONUM;
          this.compName = data[0].data[0].C_NAMCOM;
        }
        

        this.appService.setCompanyInfo(data[0]);
        this.appService.setMenu(data[1]);
        this.appService.setAllProducts(data[2]);

       /*  this.appService.setDistricts(data[3]);
        this.appService.setListServices(data[4]) */
       
       this.appService.loadCookie();
        this.isLoaded = true;
        //this.spinner.hide();
        console.log('load catalog');
      },
      (error) => {
        //this.spinner.hide();
        //this.snackBar.open('Algo ocurrio por favor trate nuevamente.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    );
  }
}
