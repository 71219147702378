import { Component, OnInit,Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-modal-initial',
  templateUrl: './modal-initial.component.html',
  styleUrls: ['./modal-initial.component.scss']
})
export class ModalInitialComponent implements OnInit {

  
  closeBtnName: string;
  title:string;
  color:string;
  list: any[] = [];
  //@Input('title') title: string = "";
  //@Input('color') color: string = "";

  constructor(public bsModalRef: BsModalRef) {}
 
  ngOnInit() {
   
  }
  verifyColor(){
    if(this.color=='error'){
      return true;
    }
    else{
      return false;
    }
  }
}
