import { Injectable } from '@angular/core';
import { AppSettingsService } from '../global/app-settings.service';
//import { HttpClient } from '@angular/common/http';  
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, retry,timeout, tap,map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Product } from '../models/app.models';
import {  Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class AppQuerysService {

  
  constructor(public settings: AppSettingsService, public http: HttpClient,public spinner:NgxSpinnerService) { }
  public nTimeout:number=20000;
  public nRetry:number=5;
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        'Backend returned code , ' +error.status+
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    //this.spinner.hide();
    //this.snackBar.open('Algo ocurrio por favor trate nuevamente.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    return throwError('Algo ocurrio por favor trate nuevamente.');
    
  };

  public getCompanyInfo(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getCompanyInfo');
    return  this.http.post(url, request ).pipe(
      tap( (c)=>{ console.log("getComnyinfo; ",c );
       }  ),
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getMenu(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getMenu');
    return  this.http.post(url , request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getProductsForPanel(request: any){
    console.log("reques Prod for panel: ", request );
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getProductsForPanel')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getProductsForPanelArray(request: any){

    console.log("Request[getProductsForPanelArray]: ", request );
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getProductsForPanel');
    return  this.http.post<Array<Product>>(url, request ).pipe(
      
     
     tap( (pp)=>{ console.log("getProductsForPanelArray: " , pp );
        } ), 
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  public getProductsForCookieArray(request: any){
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getProductsForCookie')+request;
    return  this.http.get<Array<Product>>(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  
  public getProductsForCookie(request: any){
    console.log('getProductsForCookie(req): ', request );
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getProductsForCookie')+request;
    console.log("url prod cokies: ", url );
    
    return  this.http.post(url,{}).pipe(
      tap( (rpta)=>{ console.log("getPCookies(rpta): ", rpta );
       } ),
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public setHeaderSales(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_setHeaderSales')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getDistrict(request: any){

    console.log("geTDistricts: ", request );
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getDistricts')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getStores(){

    let request:any={};
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getStores');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public setSales(request: any){
    console.log("SetSales: ", request );
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE') + this.settings.getConstantesByDominioKey('API_URL','api_setSales')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    ); 
  }
  public setDelivery(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_setDelivery')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getServices(request: any){

    console.log("getSrevice[request];: ", request );
    
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getServices')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    ); 
  }
  public getLogin(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getLogin')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public getVersion(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_getVersion')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public searchEmail(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_searchEmail')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public setSalesBatch(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_SERVICES') + this.settings.getConstantesByDominioKey('API_URL','api_setSalesBatch')+request;
    return  this.http.get(url).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }
  public setHeaderAndSalesBath(request: any){

    console.log("RequestAndSalesBath: ", request );
    

    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_setHeaderAndSalesBath');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

    //-----------usado para refrescar el logo en otro coponente------//
    private subjectt = new Subject<string>();
    sendClickEvent( data:string ){
      this.subjectt.next( data );
    }
  
    getClickEvent():Observable<any>{
      return this.subjectt.asObservable();
    }
    //------------------------------------------------------------------//


    public setClient(request: any){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_setNewCustomer');
      return  this.http.post(url, request ).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }
  
    public getAuthClient(request: any){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getAuthClient');
      return  this.http.post(url, request ).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }
  
    public getCustomersByID(request: any){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getCustomersByID');
      return  this.http.post(url, request ).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }
  
    public updCustomer(request: any){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_updCustomer');
      return  this.http.post(url, request ).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }
  
    public getHeaderSaleByCustomerId(request: any){
      const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getHeaderSaleByCustomerId');
      return  this.http.post(url, request ).pipe(
        timeout(this.nTimeout),
        retry(this.nRetry), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
    }


  HeaderSaleId:string;
  setHeaderSaleId(data){
    return new Observable<any>((obs) => { 
      console.log("setHeaderSaleId: ", data );
      this.HeaderSaleId = data;
      obs.next( this.HeaderSaleId);

    })
  }

  getHeaderSaleId(){
    return this.HeaderSaleId;
  }
  

  public getSalesTable(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getSalesTable');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getDeliverysConf(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','getDeliveryConf');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getPayMethods(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getPayMethods');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getParameters(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getParameters');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getPayMethodByParameterCodPar(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getPayMethodByParameterCodPar');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  public getHeaderSale(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getHeaderSale');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  public getIdUniqueAndIdCounter(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getIdUniqueAndIdCounter');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }


  public getPayMethodMobileConf(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getPayMethodMobileConf');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getCompanyById(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getCompanyById');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getAccountsByIdTypePayment(request: any){
    const url = this.settings.getConstantesByDominioKey('APLICACION','URL_DOMAIN_CORE_CONRUD') + this.settings.getConstantesByDominioKey('API_URL','api_getAccountsByIdTypePayment');
    return  this.http.post(url, request ).pipe(
      timeout(this.nTimeout),
      retry(this.nRetry), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }



  
}
