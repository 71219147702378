<div *ngIf="isLoaded==false" class="100vw GreenDarkprimaryColorBG">
    <img src="assets/images/conrudapx.png" class="spinner" style="width: 100px; height:auto;">
    <span style="color: white;">Con Ruda</span>

    <img src="assets/images/spinner/spinner3.svg" class="spinner2" style="width: 100px; height:auto;">
    &nbsp;
</div>
<div *ngIf="isLoaded!=false" class="100vw GreenDarkprimaryColorBG">
    <img src="assets/images/conrudapx.png" class="spinner" style="width: 100px; height:auto;">
    <span style="color: white;">Con Ruda</span>

</div>

<div class="container-full">

  
    <div style="margin-top: 50px">

        <div class="container">
          <a href="https://play.google.com/store/apps/details?id=infinitodhd.conruda.services" target="_blank"><img src="assets/images/descargaapp/google-play-badge.png" class="spinner" style="width: 40%; height:auto;"></a><br>
          <br>
          <img src="assets/images/infografias/INFOGRAFIA_CONRUDA1.jpg" class="spinner" style="width: 90%; height:auto;"><br>
          <img src="assets/images/infografias/INFOGRAFIA_CONRUDA2.jpg" class="spinner" style="width: 90%; height:auto;"><br>
          <img src="assets/images/infografias/INFOGRAFIA_CONRUDA3.jpg" class="spinner" style="width: 90%; height:auto;"><br>
          <img src="assets/images/infografias/INFOGRAFIA_CONRUDA4.jpg" class="spinner" style="width: 90%; height:auto;"><br>
          <img src="assets/images/infografias/INFOGRAFIA_CONRUDA5.jpg" class="spinner" style="width: 90%; height:auto;"><br>
          <div><h6 class="subtitle">Tiendas</h6></div>
          <div
          *ngFor="let store of stores"
          style="margin-top: 0.65em"
        >
        

      </div>
          <div style="width: 100%">
            <div style="margin: auto">
              <div
                style="margin: auto"
                class="d-flex flex-wrap justify-content-center"
              > 
                <div
                  *ngFor="let store of stores"
                  style="margin-top: 0.65em"
                >
                  <div class="card h-100" style="margin: 0px 5px 5px 5px; height: 300px">
                    <a href="google.com" target="_blank">
                    <div class="card-body">
                      <div fxflex="60" class="imgProduct">
                      <img
                        [src]="store?.C_URLIMG"
                        onError="this.src='assets/images/mobile/no-product-img.png'"
                        alt=""
                        class="imageCard"
                      />
                      </div>
                      <div style="height: 76px; padding-top: 14px" class="text-dark">
                        {{ store?.C_NAMCOM }}
                      </div>
                    </div>
                  </a>
                  </div>
                </div>
              </div>
              <div style="min-height: 25px"></div>
              
            </div>
          </div>
      
         
        </div>
      
        <!-- Carouse -->
      </div>
      
</div>