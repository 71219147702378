<div class="d-flex" id="wrapper">
  <!-- Sidebar -->
  <div
    class="bg-light border-right"
    id="sidebar-wrapper"
    [ngClass]="{ showSidebar: isShowSidebar }"
  >
    <div class="sidebar-heading d-flex justify-content-between">
      <div>
        <b><span>Mi menú</span></b>
      </div>
      <div (click)="toggleSidebar()">
        <img src="assets/images/mobile/menu4-mirror.png" class="menuIcon" />
      </div>
    </div>
    <div class="list-group list-group-flush">
      <div
        (click)="goToCategory('')"
        class="d-flex flex-row list-group-item list-group-item-action bg-light"
        style="margin: auto"
      >
        <div class="d-flex flex-row" style="margin: auto">
          <i-bs class="" name="link"></i-bs>
        </div>
        <div class="d-flex flex-row" style="margin: auto">
          <a class="text-dark mb-1 mt-2 h6 d-block">Todos </a>
        </div>
        <!-- <div class="d-flex flex-row" style="margin: auto"><img src="assets/images/mobile/C-TODOS.png" alt="" class="small-slide-right"></div> -->
      </div>
      <ng-container class="m-2" *ngFor="let slide of appService.listaMenu">
        <div
          (click)="goToCategory(slide.id)"
          class="d-flex flex-row list-group-item list-group-item-action bg-light"
          style="margin: auto"
        >
          <div class="d-flex flex-row" style="margin: auto">
            <i-bs class="" name="link"></i-bs>
          </div>
          <div class="d-flex flex-row" style="margin: auto">
            <a class="text-dark mb-1 mt-2 h6 d-block">{{ slide.c_name }} </a>
          </div>
          <div class="d-flex flex-row" style="margin: auto">
            <img
              src="assets/images/mobile/{{ slide.c_icon }}"
              alt=""
              class="small-slide-right"
            />
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- /#sidebar-wrapper -->

  <!-- Page Content -->
  <div
    id="page-content-wrapper"
    [ngClass]="{
      blockContainer: isShowSidebar,
      marginInverse: isShowSidebarRight
    }"
  >
    <div
      class="overlay"
      [ngClass]="{ overlayHide: !isShowSidebar }"
      (click)="toggleSidebar()"
    ></div>
    <div
      class="overlayRigth"
      [ngClass]="{ overlayHideRight: !isShowSidebarRight }"
      (click)="toggleSidebarRight()"
    ></div>

    <nav
      *ngIf="navComponent"
      class="navbar navbar-expand-lg navbar-light border-bottom ff0068primaryColorBG text-color-white sticky"
    >
      <div class="container flex-md-column">
        <div class="row w-100 m-0 p-0 top-cart d-flex align-items-center ">
          <div class="col-3 col-md-3 col-sm-3  col-xs-3  m-0 p-0 d-flex flex-row align-items-center">
            <i-bs name="phone"></i-bs>
            <span id="nameCompany">Llamanos: (+51) </span
            >{{ appService.companyPhone }}
          </div>

          <div class="col-6 col-md-6 col-sm-5 col-xs-3  m-0 p-0 text-center d-flex flex-row   justify-content-center align-items-center">
            <div id="nameCompany"> <span style="text-transform: capitalize;" > {{ appService.companyName }}  </span> </div>
            <img
              id="logoLargeHeader"
              [src]="appService.companyImage"
             class="logoMinHeader"
              onError="this.src='assets/images/mobile/no-product-img.png'"
              [ngClass]="{ hideLogoLarger: isShowSidebarRight }"
            />
          </div>


          <div
            *ngIf="log_success == false "
            class="col-3 col-md-3 col-sm-4  col-xs-6 m-0 p-0 d-flex flex-row justify-content-end intro-text"
          >
            <i
              class="fas fa-user fa-1x"
              style="margin-right: 7px; line-height: 2"
            ></i>

            <span
              style="margin-right: 5px; line-height: 2; cursor: pointer"
              (click)="openModal(template)"
            >
              Entrar
            </span>
            <span
              style="
                margin-left: 5px;

                cursor: pointer;
                border: solid 1px white;
                padding: 0 3px 0 3px;
                line-height: 2;
                border-radius: 4px;
              "
              (click)="goRegister()"
            >
              Registrarme
            </span>
          </div>


           <div
            *ngIf="log_success == true"
            id="bienvenidoHeader"
            
            
            class="col-3 col-md-3 col-sm-3 m-0 p-0 d-flex flex-row justify-content-end align-items-center">
            <span style="margin-right: 7px;" > Bienvenido! </span>    

  
            <span dropdown container="body" placement="bottom left" >
              <a href id="basic-link" dropdownToggle (click)="false"
                 aria-controls="basic-link-dropdown">
                 <div id="container">
                    <div id="name">
                    </div>
                  </div>
                </a>

                <div class="container">
              </div>


              <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu"
                  style="margin-top: 7px;
                    transform: translateX(-123px);
                  "
                 

                  role="menu" aria-labelledby="basic-link">
                  <div class="container__arrow container__arrow--tr"></div>

                <li>
                  <a class="dropdown-item mt-2"  (click)="goToRegister()"  > Mi cuenta </a>
                </li>
                <li>
                  <a class="dropdown-item"  (click)="logout()" > Salir </a>
                </li>
              </ul>
            </span>


              
          </div>

     

        </div>
        <div class="row w-100 m-0 p-0 mt-1 align-self-center">
          <div class="col-3 m-0 p-0 align-self-center">
            <div class="d-flex flex-row">
              <div
                style="margin-right: 10px"
                class="align-self-center"
                (click)="toggleSidebar()"
              >
                <img
                  src="assets/images/mobile/menu5.png"
                  class="menuIcon"
                />&nbsp;Menú
              </div>
              <div style="margin-right: 10px">
                <img
                  id="logoHeader"
                  (click)="goToCatalog()"
                  [src]="appService.companyImage"
                  class="logo hide-mobile"
                  onError="this.src='assets/images/mobile/no-product-img.png'"
                />
              </div>
            </div>
          </div>
          <div class="col-6 m-0 p-0 text-center align-self-center">
            <div class="align-self-center">
              <form
                [formGroup]="searchForm"
                method="get"
                class="align-self-center"
              >
                <div class="input-group align-self-center">
                  <div class="input-group align-self-center">
                    <input
                      formControlName="searchTextControl"
                      type="text"
                      class="form-control rounded-left"
                      placeholder="Buscar"
                      aria-label="Buscar"
                      aria-describedby="basic-addon2"
                      (keyup.enter)="search()"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text rounded-right"
                        id="basic-addon2"
                        (click)="search()"
                        ><i-bs name="search"></i-bs
                      ></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-3 m-0 p-0 text-right align-self-center">
            <div class="d-flex flex-row-reverse align-self-center iconCartSize">
              <div
                style="margin-left: 10px"
                class="align-self-center"
                (click)="toggleSidebarRight()"
              >
                <img
                  src="assets/images/mobile/menu5-mirror.png"
                  class="menuIcon"
                />
              </div>
              <div class="" (click)="toggleSidebarRight()">
                <i-bs class="" name="cart-check"></i-bs>
                <span
                  *ngIf="(countTotalElements$ | async) > 0"
                  class="cart-items-count"
                  >{{ countTotalElements$ | async }}</span
                >
                <!--<input type="text" readonly style="border:none; width: 45px;margin: auto;background-color: white" class="form-control input-number" value="{{countTotalElements$ | async}}" >-->
              </div>
              <span
                id="titleMyRequest"
                style="font-size: 16px; margin-top: auto; margin-bottom: auto"
                (click)="toggleSidebarRight()"
                >Mi pedido</span
              >
            </div>
          </div>
        </div>
      </div>

      <!--
        <button class="btn btn-primary" id="menu-toggle">Toggle Menu</button>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
    -->

      <!--<div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <a class="nav-link" >Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" >Link</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Dropdown
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" >Action</a>
                <a class="dropdown-item" >Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" >Something else here</a>
              </div>
            </li>
          </ul>
        </div>-->
    </nav>

    <div class="container-full">
      
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>

  <div
    class="bg-light border-right"
    id="sidebar-wrapper-right"
    [ngClass]="{ showSidebarRight: isShowSidebarRight }"
  >
    <div
      class="sidebar-heading d-flex justify-content-between"
      style="margin: auto"
    >
      <div (click)="toggleSidebarRight()">
        <img src="assets/images/mobile/menu4.png" class="menuIcon" />
      </div>
      <div style="text-align: center">
        <b><span>Mi pedido</span></b>
      </div>
    </div>

    <div class="list-group list-group-flush">
      <div
        class="list-group-item list-group-item-action bg-light"
        style="margin: auto"
      >
        <div
          class="d-flex flex-row"
          *ngFor="let productCart of cartItems$ | async"
          style="
            background-color: white;
            border-top: 1px solid #e8edf3;
            min-height: 110px;
          "
        >
          <div style="margin: auto; width: 10%">
            <i-bs
              (click)="removeProduct(productCart)"
              class=""
              style="color: #dc3545; font-size: 25px; padding-left: 0px"
              name="trash-fill"
            ></i-bs>
          </div>
          <div style="margin: 7px 7px 7px 0; width: 20%">
            <img
              *ngIf="productCart.images.length > 0"
              [src]="productCart.images[0].C_URLIMG"
              onError="this.src='assets/images/mobile/no-product-img.png'"
              alt="image"
              style="width: 100%; height: auto; max-width: 100px"
            />
            <img
              *ngIf="productCart.images.length == 0"
              src="this.src='assets/images/mobile/no-product-img.png"
              onError="this.src='assets/images/mobile/no-product-img.png'"
              alt="image"
              style="width: 100%; height: auto; max-width: 100px"
            />

          </div>
          <div style="margin: auto; width: 40%">
            <div
              style="
                color: #343a40;
                font-size: 14px;
                text-transform: lowercase;
                font-weight: 700;
              "
            >
              {{ productCart.name }}
            </div>
            <div style="color: #343a40; font-size: 11px">
              <input
                type="text"
                readonly
                style="
                  text-align: left;
                  border: none;
                  width: auto;
                  background-color: white;
                  color: #343a40;
                  font-size: 11px;
                "
                class="form-control input-number"
                value="{{ productCart.cartCount }} x S/ {{
                  productCart.newPrice
                }}"
              />

           

              <input
                *ngIf="productCart?.codsiz  "
                type="text"
                readonly
                style="
                  text-align: left;
                  border: none;
                  width: auto;
                  background-color: white;
                  color: #343a40;
                  font-size: 11px;
                "
                class="form-control input-number"
                value="Talla:  {{ productCart.codsiz }}"
              />
            </div>
            <div style="color: #00be7d; font-size: 20px">
              <input
                type="text"
                readonly
                style="
                  text-align: left;
                  border: none;
                  width: auto;
                  background-color: white;
                  color: #00be7d;
                  font-size: 20px;
                "
                class="form-control input-number"
                value="S/ {{
                  productCart.newPrice * productCart.cartCount | number: '1.2-2'
                }}"
              />
            </div>
          </div>
          <div class="text-muted d-flex flex-row" style="width: 30%">
            <i-bs
              name="dash-circle-fill"
              style="color: red; margin: auto; font-size: 26px"
              (click)="decrement(productCart)"
            ></i-bs>
            <input
              type="text"
              readonly
              style="
                border: none;
                width: 45px;
                margin: auto;
                background-color: white;
              "
              class="form-control input-number"
              value="{{ productCart?.cartCount }}"
            />
            <i-bs
              name="plus-circle-fill"
              style="color: #00be7d; margin: auto; font-size: 26px"
              (click)="increment(productCart)"
            ></i-bs>
          </div>
        </div>
        <div style="min-height: 25px">&nbsp;</div>
        <div
          style="
            color: #343a40;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
          "
        >
          Total a pagar
        </div>
        <input
          type="text"
          readonly
          style="
            border: none;
            width: auto;
            margin: auto;
            background-color: white;
            color: #00be7d;
            font-size: 20px;
          "
          class="form-control input-number hidden"
          value="S/ {{ totalPrice$ | async | number: '1.2-2' }}"
        />

        <div style="min-height: 10px">&nbsp;</div>
        <div style="text-align: center">
          <button class="buttonRounded mat-elevation-z6" (click)="goCheckout()">
            PAGAR&nbsp;
            <i-bs
              name="check2-circle"
              style="color: white; margin: auto; font-size: 26px"
            ></i-bs>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="">Ingresar</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeLoginModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post"
    [formGroup]="loginDataForm"
        (ngSubmit)="sendLogin()"
    >
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"> <i class="fas fa-envelope"></i> </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Correo Electrónico"
          aria-label="Correo Electrónico"
          aria-describedby="basic-addon1"
          formControlName="C_EMACUS"
        />
        
      </div>
      <div  class="error-div" *ngFor="let error of errorMsm.C_EMACUS">
        <ng-container
          *ngIf="C_EMACUS.hasError(error.type) && C_EMACUS.touched"
        >
          <small class="form-text text-danger">
            {{ error.message }}
          </small>
        </ng-container>
      </div>

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text"  style="padding-right: 14px;" id="basic-addon1"><i class="fas fa-lock"></i>  </span>
        </div>
        <input
          type="password"
          class="form-control"
          placeholder="Contraseña"
          aria-label="Contraseña"
          aria-describedby="basic-addon1"
          formControlName="C_PASS"   
        />  
        
      </div>
      <div  class="error-div" *ngFor="let error of errorMsm.C_PASS">
        <ng-container
          *ngIf="C_PASS.hasError(error.type) && C_PASS.touched"
        >
          <small class="form-text text-danger">
            {{ error.message }}
          </small>
        </ng-container>
      </div>

      <div *ngIf="log_err" class="alert alert-danger"><strong>Alerta:</strong> Correo o contraseña invalido. </div>

      <input type="submit" class="btn btn-primary  btn-block btnIngreso" value="ENVIAR" >

    </form>
  </div>
</ng-template>





