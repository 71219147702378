import { Component, OnInit,ChangeDetectorRef, TemplateRef, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { AppSettingsService } from '../global/app-settings.service';
import { AppService } from '../core/app.service';
import {  ActivatedRoute, Router } from '@angular/router';
import {  FormGroup, Validators,FormControl, FormBuilder} from '@angular/forms';
import{Product} from '../models/app.models'
import{UtilsCoreService} from '../core/utils-core.service'
import { AppState } from '../models/app-state.model';
import * as actionsCataloging from './catalog/store/actions/cataloging.actions';
import { async } from '@angular/core/testing';
import { take } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import {ModalComponent} from './shared/modal.component'
import { AppQuerysService } from '../core/app-querys.service';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { environment } from 'src/environments/environment';

export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    placement: 'bottom',
    container: 'body',
    triggers: 'focus',
    delay: 100
  });
}

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [{ provide: PopoverConfig, useFactory: getPopoverConfig }]
})

export class PagesComponent implements OnInit,AfterViewInit {
  public searchForm: FormGroup;
  public searchText:String="";
  public isShowSidebar:boolean=false;
  public isShowSidebarRight:boolean=false;
  public cartItems$: Observable<Array<Product>>;
  searchTextControl = new FormControl('');
  countTotalElements$:Observable<number>;
  totalPrice$:Observable<number>;
  bsModalRef: BsModalRef;
  loginDataForm: FormGroup;
  public idcompany = localStorage.getItem(environment.idcompanyweb)
  pattern = /^[^0-9][a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[@][a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,4}$/

  public errorMsm = {
    C_EMACUS: [ {type:'required',message:"El email es requerido." }, { type:'pattern',message:"Ingrese un email válido porfavor." }],
    C_PASS: [ {type:'required',message:"La contraseña es requerida." }, ],
  }
  get C_EMACUS(){
    return this.loginDataForm.get('C_EMACUS');
  }
  
  get C_PASS(){
    return this.loginDataForm.get('C_PASS');
  }


  constructor(public appSettings:AppSettingsService,
    private router: Router,
    public appService:AppService,
    
    public store: Store<AppState>,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private _fb: FormBuilder, 
    private appQuerysService:AppQuerysService,
    private cdr:ChangeDetectorRef,
    private route: ActivatedRoute
    ) {
      this.searchForm = new FormGroup({
        searchTextControl: new FormControl('', [Validators.nullValidator])
      });

      this.loginDataForm = this._fb.group({
        C_EMACUS: [ , [ Validators.required,Validators.pattern(this.pattern) ] ],
        C_PASS:[ , [Validators.required] ]
      })
     }

     navComponent = true;
  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      console.log("PARMAMA: " , params);
      
      const id = +params.get('category');
      console.log("cosmosL: ", id );
      
    });















    console.log('pages-ngoninit');
    this.countTotalElements$ = this.store.pipe(select(store=>store.cataloging.countTotalElements));


    this.cartItems$ = this.store.pipe(select(store=>store.cataloging.cart));

    this.cartItems$.subscribe( ( rpta )=>{
      console.log("this.cartItems$: ", rpta );
      
    } )
    
    this.appQuerysService.getClickEvent().subscribe( (e) =>{
    console.log( 'PAges Compo: ' , e );
        if( e == 'CatalogComponent' || e == 'CheckoutComponent' || e == 'PagesComponent' ){
          this.navComponent = true
          this.cdr.detectChanges();
        }else{
          this.navComponent = false
          this.cdr.detectChanges();
        }
    
    this.getIdClient()} )
  

    this.totalPrice$= this.store.pipe(select(store=>store.cataloging.totalPrice));

  }

  ngAfterViewInit(){
    console.log("AFTERVIEW INIT");
    
    //this.getIdClient(); 

  }

  setLetterinProfile(name:string){
    var initials = name.charAt(0).toUpperCase(); ;
    let name_tag =  document.getElementById("name")
    if( name_tag ){
      name_tag.innerHTML = initials;
    }

  }


  getIdClient() {
    console.log( "idCleinte: " , localStorage.getItem(environment.idClient) );
    const idClient = localStorage.getItem(environment.idClient)
    if( idClient != null  ){
        let request = { 'IDCUSTOMER': idClient };
        this.appQuerysService.getCustomersByID( request ).subscribe( (client:any)=>{
          console.log("Cliente(pagesom): ", client );
        
          this.log_success  = true;
          this.cdr.detectChanges();
          let name = client.data[0].C_NAMCUS;
          this.setLetterinProfile( name );
          //this.appService.cleanCookies();
         
          
        } )
    }else{
      this.log_success  = false;
    }  
  }
  public removeProduct(product:Product){
    this.store.dispatch(new actionsCataloging.DeleteElementCartAction(product.id , product.codsiz ));
    if(this.getStateError(this.store)){
      let e=this.getStateError(this.store);
      this.openBarMessage(e.message,'error');
    }
    if(this.getStateMessage(this.store)){
      let e=this.getStateMessage(this.store);
      this.appService.updateCookie();
      this.openBarMessage(e.toString(),'');
    }
    this.appService.updateCookie();
  }

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeLoginModal(){
    this.modalRef.hide()
    this.loginDataForm.reset();
    this.log_err = false;

  }

  
  onActivate(event) {
  /*   console.log("OnActivate: ", event.constructor.name ) ;
    let component =  event.constructor.name
    if( component == 'CatalogComponent' || component == 'CheckoutComponent' ){
      this.component = true
    }else{
      this.component = false
    } */
     


    window.scroll(0,0);
    document.body.scrollTop = 0;
    document.querySelector('body').scrollTo(0,0);
}

  public goCheckout(){
    this.toggleSidebarRight();
    this.router.navigate(['/ec/checkout']);     
  }

  public goRegister(){
    this.router.navigate(['/ec/register']);
  }

  public increment(product:Product){

    this.store.dispatch(new actionsCataloging.IncrementCounterElementCartAction(product.id,1 , product.codsiz ));    
    this.cd.detectChanges();

    if(this.getStateError(this.store)){
      let e=this.getStateError(this.store);
      this.openBarMessage(e.message,'error');
    }
    if(this.getStateMessage(this.store)){
      let e=this.getStateMessage(this.store);
      this.appService.updateCookie();
      this.openBarMessage(e.toString(),'');
    }
  }

  public decrement(product:Product){
    this.store.dispatch(new actionsCataloging.DecrementCounterElementCartAction(product.id , product.codsiz ));
    this.cd.detectChanges();
    if(this.getStateError(this.store)){
      let e=this.getStateError(this.store);
      this.openBarMessage(e.message,'');
      //this.openSnackBarError(e.message, '');
    }
    if(this.getStateMessage(this.store)){
      let e=this.getStateMessage(this.store);
      this.appService.updateCookie();
      this.openBarMessage(e.toString(),'');
      //this.openSnackBarSucess(e.toString(), '');
    }
  }
  openBarMessage(msg:any,color:any) {
    const initialState = {
      title: msg,
      color: color,
    };
    const config={
      animated: false,
      backdrop: false,
      ignoreBackdropClick: false
    };
    //this.bsModalRef = this.modalService.show(ModalComponent, {initialState,backdrop: true,ignoreBackdropClick: false});
    this.bsModalRef= this.modalService.show(ModalComponent, Object.assign({initialState},config, {}));
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  public getStateError(store: Store<AppState>): Error {
    let state: Error;
    store.select(store =>store.cataloging.error).pipe(take(1)).subscribe(
       s => state = s
    );
    return state;
 }
 public getStateMessage(store: Store<AppState>): String {
  let state: string;
  store.select(store =>store.cataloging.message).pipe(take(1)).subscribe(
     s => state = s
  );
  return state;
}
  public toggleSidebar(){
    this.isShowSidebar=!this.isShowSidebar;
  }
  public toggleSidebarRight(){

    window.scroll(0,0);
    document.body.scrollTop = 0;
    document.querySelector('body').scrollTo(0,0);

    this.isShowSidebarRight=!this.isShowSidebarRight;
  }
  public goToCategory(category:any){

    this.searchForm.controls.searchTextControl.setValue('');
    this.appService.category=category;
    this.appService.pageToLoad=1;
    this.appService.searchText='';
    this.toggleSidebar();
    this.cd.detectChanges();
    
    let request:any = this.appService.getRequestAllProducts();

    console.log("__Request categiryGo: ", request );
    

    this.store.dispatch(new actionsCataloging.LoadInitialCatalogingAction(request));
    this.router.navigateByUrl(`/ec/store/${this.idcompany}/catalog`)

    //this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> )
    
    
    
  }

  goToCatalog(){
    this.router.navigateByUrl(`/ec/store/${this.idcompany}/catalog`) 
  }


  public search(){
    this.searchText=this.searchForm.value.searchTextControl;
    event.stopPropagation();
    event.preventDefault();
    this.appService.products=[];
    this.appService.pageToLoad=1;
    this.appService.category='-';
    this.appService.searchText=this.searchText;
    let request:any = this.appService.getRequestAllProducts();
    this.store.dispatch(new actionsCataloging.LoadInitialCatalogingAction(request));
  }


  log_success = false;
  log_err = false;
  sendLogin(){

    for (let u in this.loginDataForm.controls) {
      this.loginDataForm.controls[u].markAllAsTouched();
    }
    if (this.loginDataForm.valid) {
      console.log("Formulario valido!");
      console.log("login Form ", this.loginDataForm.value );

      this.appQuerysService.getAuthClient(  this.loginDataForm.value  ).subscribe( (authClient:any)=>{
        console.log("AuthClient: ", authClient );
        if( authClient.status === "true" ){

          this.log_success  = true;
          this.log_err = false;
          this.loginDataForm.reset();
          this.cdr.detectChanges();
          this.modalRef.hide();

          let name = authClient.data[0].C_NAMCUS;
          this.setLetterinProfile( name );


          localStorage.setItem( environment.idClient ,authClient.data[0].ID  )
          this.appQuerysService.sendClickEvent('PagesComponent')


        }else{
           this.log_success  = false;
           this.log_err = true;
        }
        
      } )
      
    }

  }

  logout(){
    console.log("vamos a salir!");
    localStorage.removeItem(environment.idClient)
    this.log_success = false;
    
  }


  goToRegister(){
    console.log("Vamos a register");
    this.router.navigate(['/ec/register'])
    
  }



}




