import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent} from './pages/pages.component';
import { CatalogModule} from './pages/catalog/catalog.module';
import { CheckoutModule} from './pages/checkout/checkout.module';
import { StoresComponent } from './pages/stores/stores.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { 
      path: 'ec', 
      component: PagesComponent, children: [
          
          { path: 'store', loadChildren: () => import('./pages/catalog/catalog.module').then(m => m.CatalogModule) },
          { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
          { path: 'orders', loadChildren: () => import('./pages/client-register/orders/orders.module').then(m => m.OrdersModule ) },
          { path: 'register', loadChildren: () => import('./pages/client-register/client-register.module').then(m => m.ClientRegisterModule ) },
          { path: 'clients', loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule ) },
          
      ]
  },
  { 
    path: '', component: HomeComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }