import { Component, OnInit,HostListener  } from '@angular/core';
declare var $: any;
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})




export class HomeComponent implements OnInit {
  scrollvalue:any=false;
  scrollvalueToTarget:any=false;
  constructor() { }

  testimonialOptions: OwlOptions = {
    loop: true,
    margin: 30,
    nav: false,
    dots: true,
    //responsiveClass: true,
    autoplay: true,
    autoplayHoverPause: true,
    lazyLoad: true,
    items: 1

  }


  customOptions: OwlOptions = {
    loop: true,
    margin: 0,
    center: true,
    dots: true,
    nav: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
onScroll(event) {
  if(window.pageYOffset>0){
    this.scrollvalue=true;
  }
  else{
    this.scrollvalue=false;
  }

  if(window.pageYOffset>1){
    this.scrollvalueToTarget=true;
  }
  else{
    this.scrollvalueToTarget=false;
  }
  
}

  ngOnInit(): void {
  }

}
