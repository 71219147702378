import { Injectable } from '@angular/core';
import { AppSettingsService } from '../global/app-settings.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, timeout } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Product } from '../models/app.models';
import { AppQuerysService } from '../core/app-querys.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store, select } from '@ngrx/store';
import { AppState } from '../models/app-state.model';
import { take } from 'rxjs/operators';
import * as actionsCataloging from '../pages/catalog/store/actions/cataloging.actions';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isShowCategory: boolean = true;
  public nTimeout: number = 20000;
  public nRetry: number = 5;
  public companyAddress: String = '';
  public companyPhone: String = '';
  public companyEmail: String = '';
  public companyName: String = '';
  public companyImage:string = '';
  public companydeliverymount:string = '';


  public companyAttention: String = '';
  public response: any = {};
  public listaMenu: any = [];
  public listServices: any = [];
  public serviceSelected: any;
  public districts: any = [];
  public category: String = '';
  public pageToLoad: number = 1;
  public products: Array<Product> = [];
  public searchText: String;
  public latestRequest: any;
  public isNoMoreProduct: boolean = false;
  public isLoading: boolean = false;
  public idHeaderSales: any = '';
  public idCustomer: any = '';
  public idUnique: any = '';
  public idTypePayment = '';
  public idService = '';

  constructor(
    public appSettings: AppSettingsService,
    public http: HttpClient,
    public cookieService: CookieService,
    public appQuerys: AppQuerysService,
    public spinner: NgxSpinnerService,
    public store: Store<AppState>
  ) {}
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        'Backend returned code , ' + error.status + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    //this.spinner.hide();
    //this.snackBar.open('Algo ocurrio por favor trate nuevamente.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    return throwError('Algo ocurrio por favor trate nuevamente.');
  }
  public updateCookie() {
    console.log("UpdateCookie");
    
    let elemenstCart: Product[] = [];
    let strElementsCart: any = '';
    let countElement: any = 0;
    let cartList: Product[] = this.getStateCart(this.store);

    for (let cartElement of cartList) {

      if (countElement > 0) {
        strElementsCart += 'A';
      }

      if( cartElement.codsiz != null ){
        strElementsCart += cartElement.id.toString() + '-' + cartElement.cartCount.toString()+":"+cartElement.codsiz;
      }else{
        strElementsCart += cartElement.id.toString() + '-' + cartElement.cartCount.toString();
      }
      


      countElement++;
    }

    console.log("Str: ", strElementsCart);


    this.cookieService.set('cartList', strElementsCart);
  }
  public getStateCart(store: Store<AppState>): Product[] {
    let state: any[];
    store
      .select((store) => store.cataloging.cart)
      .pipe(take(1))
      .subscribe((s) => (state = s));
    return state;
  }
  public getRequestAllProducts() {
    let categoryToSend: String = '';
    let SearchTextToSend: String = '';
    if (typeof this.category == 'undefined' || this.category == '') {
      categoryToSend = '-';
    } else {
      categoryToSend = this.category;
    }
    if (this.searchText == '' || typeof this.searchText == 'undefined') {
      SearchTextToSend = '-';
    } else {
      SearchTextToSend = this.searchText;
    }
    //let request=this.appSettings.app.idcompany+"/"+categoryToSend+"/"+SearchTextToSend+"/"+this.pageToLoad+"/"+this.appSettings.app.itemPerQueryWeb;
    let request = {
      idCompany: localStorage.getItem(environment.idcompanyweb),
      tope: 5,
      categoryToSend,
      SearchTextToSend,
      numberPage: this.pageToLoad,
    };

    if (request == this.latestRequest) {
      if (this.isNoMoreProduct) {
        return false;
      }
    } else {
      this.latestRequest = request;
      this.isNoMoreProduct = false;
    }
    this.pageToLoad++;
    return request;
  }
  public getAllProducts() {
    let categoryToSend: String = '';
    let SearchTextToSend: String = '';
    if (typeof this.category == 'undefined' || this.category == '') {
      categoryToSend = '-';
    } else {
      categoryToSend = this.category;
    }
    if (this.searchText == '' || typeof this.searchText == 'undefined') {
      SearchTextToSend = '-';
    } else {
      SearchTextToSend = this.searchText;
    }
    //let request=this.appSettings.app.idcompany+"/"+categoryToSend+"/"+SearchTextToSend+"/"+this.pageToLoad+"/"+this.appSettings.app.itemPerQueryWeb;

    let request = {
      idCompany: this.appSettings.app.idcompany,
      tope: 5,
      numberPage: this.pageToLoad,
    };

    if (request == this.latestRequest) {
      if (this.isNoMoreProduct) {
        return false;
      }
    } else {
      this.latestRequest = request;
      this.isNoMoreProduct = false;
    }

    this.spinner.show();
    this.isLoading = true;

    console.log("getProductsForPanel(Request: )", request );
    

    this.appQuerys.getProductsForPanel(request).subscribe(
      (data) => {
        console.log("PPPPP: ", data );
        
        this.pageToLoad++;
        let arrayForOrder: any = [];
        let arrayNew: any = [];
        if (this.pageToLoad <= 1) {
          this.response = data;
          arrayNew = data;
          arrayForOrder = data;
          arrayForOrder = arrayForOrder.sort(
            (b, a) => a.availibilityReal - b.availibilityReal
          );
          this.products = arrayForOrder;
        } else {
          let arrayTemp: any = [];

          let arrayComplete: any = [];

          arrayTemp = this.products;
          arrayNew = data;
          arrayComplete = arrayTemp.concat(arrayNew);
          arrayForOrder = arrayComplete.sort(
            (b, a) => a.availibilityReal - b.availibilityReal
          );
          this.products = arrayForOrder;
        }

        if (arrayNew.length == 0) {
          this.pageToLoad--;

          this.isNoMoreProduct = true;
        } else {
          this.isNoMoreProduct = false;
        }
        this.isLoading = false;

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        //this.snackBar.open('Algo ocurrio por favor trate nuevamente.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    );
  }



  public setDistricts(data: any) {
    let response: any = {};
    response = data;
    this.districts = response.items;
    //this.spinner.hide();
  }
  public cleanCookies() {
    console.log('update cookie');
    this.cookieService.set('cartList', '');
  }




  public loadCookie() {
    console.log('load cookie');

    let stringCardList = this.cookieService.get('cartList');
    console.log('Cookieu_____: ', stringCardList);

    if (stringCardList != '') {

      let arrayCardList = stringCardList.split('A');
      for (let cartElement of arrayCardList) {
        let cartElementCom = cartElement.split('-');
        let idElement: number = Number.parseInt(cartElementCom[0]);
        let quaElement = cartElementCom[1];
        let productTemp: Product;

        console.log("prrdocus: ", this.products );
        console.log("cartElementCom[0]", cartElementCom[0] );

        for (let productElement of this.products) {
          if (productElement.id == idElement) {
            let price = 0

            if( productElement.size == null ){
              price = productElement.flagOffer == '1'? Number(productElement.origPrice) : Number(productElement.newPrice)

            }else{
              price = productElement.size[0].flagOffer == '1'? Number(productElement.size[0].origPrice) : Number( productElement.size[0].price)
            }

            productTemp = productElement;
            productTemp.newPrice = price; 
            productTemp.cartCount = Number.parseInt(quaElement);
            productTemp.catalogCount = Number.parseInt(quaElement);
            break;
          }
        }
        console.log('To cart_____: ', productTemp);

        this.store.dispatch(
          new actionsCataloging.InsertElementCartAction(productTemp)
        );
      }




    }
  }

  public setCompanyInfo(data: any) {
    try{
      console.log('SetCompany info: ', data);

      let response: any = {};
      response = data.data[0];
      console.log('SetCompany info,rsponse: ', response);
  
      this.companyName = response.C_NAMCOM;
      this.companyAddress = response.C_ADDCOM;
      this.companyPhone = response.C_PHONUM;
      this.companyEmail = response.C_EMAIL;
      this.companyImage = response.C_URLIMG;
      this.companydeliverymount = response.N_DELIVERYMOUNT;
    }
    catch (error) {
      console.log(error);
    }

    //this.companyAttention=response.c_schatte;
  }
  public setListServices(data: any) {
    let response: any = {};
    response = data;
    this.listServices = data.items;
    this.serviceSelected = this.listServices[0];
  }
  public setCategories(data: any) {
    //this.Data.categories = data;
  }

  public setAllProducts(data: any) {
    let arrayForOrder: any = [];
    this.response = data;
    arrayForOrder = data;
   /*  arrayForOrder = arrayForOrder.sort(
      (b, a) => a.availibilityReal - b.availibilityReal
    ); */
    this.products = arrayForOrder;
    console.log("PRODUCTss: ", arrayForOrder );
    
  }
  public setMenu(response: any) {
    console.log('response: setMenu: ', response);

    this.listaMenu = response.data.items;
    console.log('listaMenu: ', this.listaMenu);

    //this.spinner.hide();
  }
}
