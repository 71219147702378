import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { Alarm, AlarmFill, Phone,CartCheck,
  Search,InfoCircle,CartPlus,PlusCircleFill,DashCircleFill,PlusCircle,Link,TrashFill,
Check2Circle } from 'ngx-bootstrap-icons';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './global/app-interceptor';
import { StoreModule } from '@ngrx/store';
import {  ActionReducer, MetaReducer } from '@ngrx/store';
import { CatalogingReducer } from './pages/catalog/store/reducers/cataloging.reducer';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { CatalogingEffects } from './pages/catalog/store/effects/cataloging.effects';
import { ModalComponent } from './pages/shared/modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalInitialComponent } from './pages/shared/modal-initial/modal-initial.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from './core/currency.pipe';
import { StoresComponent } from './pages/stores/stores.component';
import { HomeComponent } from './pages/home/home.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OrderPipe } from './pipes/order.pipe';
import { ClientsComponent } from './pages/clients/clients.component';

const icons = {
  Alarm,
  AlarmFill,
  Phone,
  CartCheck,
  Search,
  InfoCircle,
  CartPlus,
  PlusCircleFill,
  DashCircleFill,
  PlusCircle,
  Link,
  TrashFill,
  Check2Circle
};
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    console.log('state', state);
    console.log('action', action);
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<any>[] = [debug];
@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    ModalComponent,
    ModalInitialComponent,
    StoresComponent,
    HomeComponent,


   
  ],
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    AlertModule.forRoot(),
    NoopAnimationsModule,
    NgxBootstrapIconsModule.pick(icons),
    HttpClientModule,
    StoreModule.forRoot(
      { cataloging:CatalogingReducer }, 
      { metaReducers },
      ),
      EffectsModule.forRoot([CatalogingEffects]),
      FormsModule,
     ReactiveFormsModule,
     ModalModule.forRoot(),
     NgxSpinnerModule,
     CarouselModule,
     
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent ,]
})
export class AppModule { }
