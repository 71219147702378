import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, concatMap } from 'rxjs/operators';

import * as actionsCataloging from '../actions/cataloging.actions'
import { of,Observable } from 'rxjs';
import { AppService } from '../../../../core/app.service';
import { AppQuerysService } from '../../../../core/app-querys.service';
import { AppSettingsService } from '../../../../global/app-settings.service';
import { Product } from 'src/app/models/app.models';
import { Action } from 'rxjs/internal/scheduler/Action';
@Injectable()
export class CatalogingEffects {
  /*
  @Effect() InsertElementCartAction$ = this.actions$
  .pipe(
    ofType<actionsCataloging.InsertElementCartAction>( actionsCataloging.CatalogingActionTypes.INSERT_ELEMENT_CART)
  )
  */
  @Effect() loadCart$ = this.actions$
    .pipe(
      ofType<actionsCataloging.LoadInitialCartAction>( actionsCataloging.CatalogingActionTypes.LOAD_INITIAL_CART),
      mergeMap(
        (request) =>{
            //let request="381/Abarrotes/-/1/10";
            return this.appQuerys.getProductsForCookieArray(request.catalog)
            .pipe(
              map(data => {
                console.log("data initial cookie: ", data );
                


                return new actionsCataloging.LoadInitialCartSuccessAction(data)
              }),
              catchError(error => of(new actionsCataloging.LoadInitialCartFailureAction (error)))
            );
        } 
      ),
  )
  @Effect() loadCataloging$ = this.actions$
    .pipe(
      ofType<actionsCataloging.LoadCatalogingAction>( actionsCataloging.CatalogingActionTypes.LOAD_CATALOGING),
      mergeMap(
        (request) =>{
            //let request="381/Abarrotes/-/1/10";
            console.log("REquest getProds1: ",request.catalog );
            
            return this.appQuerys.getProductsForPanelArray(request.catalog)
            .pipe(
              map(data => {
                console.log( "rpta de proudctos: ", data );

                let catalog = []
                data.forEach( element => {
                  console.log("Elementt: ", element);
                  
  
                  if( element.flagOffer == '1' ){
                    
                      let object ={
                        ...element,
                        newPrice:""+element.origPrice,
                       origPrice: ""+element.newPrice.toString()
  
                      }
                      console.log("OBject: " , object);
                      
  
                      catalog.push( object   )
  
                  }else{
                    catalog.push( element )
                  }
  
  
  
                  
                  
                });
  



                return new actionsCataloging.LoadCatalogingSuccessAction(catalog)
              }),
              catchError(error => of(new actionsCataloging.LoadCatalogingFailureAction(error)))
            );
        } 
      ),
  )
  @Effect() loadInitialCataloging$ = this.actions$
  .pipe(
    ofType<actionsCataloging.LoadInitialCatalogingAction>( actionsCataloging.CatalogingActionTypes.LOAD_INITIAL_CATALOGING),
    mergeMap(
      (request) =>{
          //let request="381/Abarrotes/-/1/10";
          console.log("REquest getProds2: ",request.catalog );
          return this.appQuerys.getProductsForPanelArray(request.catalog)
          .pipe(
            map(data => {
            console.log( "rpta de proudctos: ", data );

              let catalog = []
              data.forEach( element => {
                console.log("Elementt: ", element);
                

                if( element.flagOffer == '1' ){
                  
                    let object ={
                      ...element,
                      newPrice:""+element.origPrice,
                     origPrice: ""+element.newPrice.toString()

                    }
                    console.log("OBject: " , object);
                    

                    catalog.push( object   )

                }else{
                  catalog.push( element )
                }


                
              });

              

              return new actionsCataloging.LoadInitialCatalogingSuccessAction(catalog)
            }),
            catchError(error => of(new actionsCataloging.LoadInitialCatalogingFailureAction(error)))
          );
      } 
    ),
)

/*
  @Effect() addShoppingItem$ = this.actions$
    .pipe(
      ofType<AddItemAction>(ShoppingActionTypes.ADD_ITEM),
      mergeMap(
        (data) => this.shoppingService.addShoppingItem(data.payload)
          .pipe(
            map(() => new AddItemSuccessAction(data.payload)),
            catchError(error => of(new AddItemFailureAction(error)))
          )
      )
  )

  @Effect() deleteShoppingItem$ = this.actions$
    .pipe(
      ofType<DeleteItemAction>(ShoppingActionTypes.DELETE_ITEM),
      mergeMap(
        (data) => this.shoppingService.deleteShoppingItem(data.payload)
          .pipe(
            map(() => new DeleteItemSuccessAction(data.payload)),
            catchError(error => of(new AddItemFailureAction(error)))
          )
      )
    )
*/
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private appQuerys: AppQuerysService,
    private appSettings: AppSettingsService
    
  ) { }
}