import { Component, OnInit } from '@angular/core';
import { AppQuerysService } from '../../core/app-querys.service';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {

  public isLoaded: boolean = false;
  public stores:any=[];
  public elementsReturned:any={};
  product
  
  public loading: boolean = false;
  constructor(private appQuerys: AppQuerysService) { 
    
  }

  ngOnInit(): void {
    let response:any=[];
    this.appQuerys.getStores().subscribe((data) => {
      response=data;
      this.stores=response.data;
    });
  }
  viewStore(store:any){
    
  }
  cargarMas(){}
}
